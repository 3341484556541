import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import Dexie from 'dexie';

import type ObjectStore from 'o365.pwa.modules.client.dexie.objectStores.ObjectStore.ts';
import type { Dexie as DexieInstance } from 'o365.pwa.declaration.sw.dexie.d.ts';
import type * as DatabaseModule from 'o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts';

export class Database implements DatabaseModule.Database {
    static objectStoreDexieSchema: string = "&[appId+id],id,appId";

    public id!: string;
    public appId!: string;

    public version: number = 1;
    public installedSchema: { [key: string]: string } = {};

    public get name(): string {
        return `O365_PWA_${this.appId.toUpperCase()}_${this.id.toUpperCase()}`; 
    }

    public get objectStores() {
        const database = this;

        return new Proxy<DatabaseModule.ObjectStores>(<DatabaseModule.ObjectStores>{
            getAll: async () => {
                return await IndexedDbHandler.getObjectStores(database.appId, database.id);
            }
        }, {
            get(target, prop, receiver) {
                if (prop in target) {
                    return Reflect.get(target, prop, receiver);
                }

                return new Promise<ObjectStore | null>(async (resolve, reject) => {
                    try {
                        const objectStore = await IndexedDbHandler.getObjectStore(database.appId, database.id, prop.toString());
                    
                        resolve(objectStore);
                    } catch (reason) {
                        reject(reason);
                    }
                });
            }
        });
    }

    public get databaseSchema(): Promise<{[key: string]: string}> {
        return new Promise(async (resolve, reject) => {
            try {
                const objectStores = await this.objectStores.getAll();

                const databaseSchema: { [ key: string ]: string } = {};

                for (const objectStore of objectStores) {
                    const objectStoreSchema = await objectStore.schema;

                    // TODO: Add check if objectstore has app/database override

                    databaseSchema[objectStore.id] = objectStoreSchema;
                }

                resolve(databaseSchema);
            } catch (reason) {
                reject(reason);
            }
        });
    }

    public get upgradeNeeded(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const newScheme = await this.databaseSchema;

                const keys1 = Object.keys(this.installedSchema).sort();
                const keys2 = Object.keys(newScheme).sort();

                if (keys1.length !== keys2.length) {
                    resolve(true);
                }

                for (let i = 0; i < keys1.length; i++) {
                    const key1 = keys1[i];
                    const key2 = keys2[i];

                    if (key1 !== key2 || this.installedSchema[key1] !== newScheme[key2]) {
                        resolve(true);
                    }
                }

                resolve(false);
            } catch (reason) {
                reject(reason);
            }
        });
    }

    public get dexieInstance(): Promise<InstanceType<typeof DexieInstance>> {
        return new Promise(async (resolve, reject) => {
            try {
                const dexieInstance = new Dexie(this.name);

                dexieInstance.version(this.version).stores(this.installedSchema);

                await dexieInstance.open();

                resolve(dexieInstance);
            } catch (reason) {
                reject(reason);
            }
        });
    }

    constructor(id: string, appId: string) {
        this.id = id;
        this.appId = appId;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateDatabase(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteDatabase(this);
    }

    public async forceReload(): Promise<Database | null> {
        return await IndexedDbHandler.getDatabaseFromIndexedDB(this.appId, this.id);
    }

    // TODO: Look into passing in upgrade scripts
    public async initialize(): Promise<void> {
        if (await this.upgradeNeeded === false) {
            return;
        }

        const databaseSchema = await this.databaseSchema;

        this.version++;

        this.installedSchema = databaseSchema;
        
        await this.dexieInstance;

        this.save();

        // console.log('Upgraded Dexie instance to: ', this.installedSchema);
    }
}

export default Database;
