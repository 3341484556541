if(self.document === undefined){
    importScripts(`/nt/cdn/protobuf/6.10.0/index.js`);

    // v4 -Added ObjectIds (to replace globalIds on long term)
    // v5 -Added tintColor, tintColorStrength and overrideOpacity to ProdInstances.
    // v6 13/07/2022 - Removed GlobalId and individual transparent meshes
    // v7 21/12/2022 - Added solids
    const OmgContent = protobuf.parse(`
        syntax = "proto3";
        package OmgFileMessage;

        option csharp_namespace = "OmgFileMessage";

        message OmgContent {
            int32 version = 1;
            //repeated string globalIdLookup = 2;
            repeated string classNameLookup = 3;
            MergedMesh mergedOpaqueMesh = 4;
            repeated MergedMesh mergedTransparentMeshes = 5;
            //repeated Mesh transparentMeshes = 6;
            //repeated uint32 transparentMeshesGlobalIds = 7;
            //repeated uint32 transparentMeshesClassNames = 8;
            //repeated float transparentMeshesBounds = 9;
            //bytes transparentMeshesColors = 10;
            //repeated float transparentMeshesMatrices = 11;
            repeated Mesh instanceMeshes = 12;
            repeated ProdInstance prodInstances = 13;
            uint32 instancesOpaqueTotalVertices = 14;
            uint32 instancesOpaqueTotalIndices = 15;
            uint32 instancesTransparentTotalVertices = 16;
            uint32 instancesTransparentTotalIndices = 17;
            uint32 instancesNumOpaque = 18;
            uint32 instancesNumTransparent = 19;
            //repeated int32 transparentMeshesObjectIds = 20;
            int32 jobId = 21;
            uint32 instancesOpaqueTotalSolids = 22;
            uint32 instancesTransparentTotalSolids = 23;
            MergedMesh mergedLineMesh = 24;
            repeated string fonts = 25;
            repeated MergedMesh mergedTextMesh = 26;
            MergedMesh mergedPointMesh = 27;
        }
        message MergedMesh{
            repeated float vertices = 1;
            repeated int32 indices = 2;
            repeated float bounds = 3;
            repeated int32 indexOffsets = 4;
            repeated int32 verticeOffsets = 5;
            bytes alpha = 6;
            bytes colors = 7;
            //repeated uint32 globalIds = 8;
            repeated uint32 classNames = 9;
            repeated float worldPosition = 10;
            repeated int64 objectIds = 11;
            repeated int32 solidIndices = 12;
            repeated int32 solidIndexOffsets = 13;
            repeated float uvs = 14;
        }
        message Mesh{
            repeated float vertices = 1;
            repeated int32 indices = 2;
            repeated int32 solidIndices = 3;
        }
        message InstanceGeometry{
            int32 meshId = 1;
            bytes color = 2;
            repeated double matrix = 3;
        }
        message ProdInstance{
            repeated InstanceGeometry geometries = 1;
            repeated float bounds = 2;
            //uint32 globalId = 3;
            uint32 className = 4;
            int64 objectId = 5;
            bytes tintColor = 6;
            float tintColorStrength = 7;
            float overrideOpacity = 8;
        }
    `).root.OmgFileMessage.lookupType("OmgContent");

    const cachePromise = caches.open("omega365Bimviewer");

    const USE_CACHE = true;

    getResponse = async function(event) {
        const url = event.data.url;

        if (!USE_CACHE) {
            return await fetch(url);
        }

        const cache = await cachePromise;
        let response = await cache.match(url)
        //console.log(event, response);

        if (!response) {
            response = await fetch(url);
            if (!response.ok) {
                throw "response not ok!";
            }
            cache.put(url, response.clone());
        }

        return response;
    }

    onmessage = async function(event) {
        const workerResponse = {
            workerIndex: event.data.workerIndex,
            task: event.data,
            buffers: [],
        }

        const url = event.data.url;
        
        let omgContent;
        try {
            let response = await getResponse(event);

            const arrayBuffer = await response.arrayBuffer();
            if (arrayBuffer) {
                omgContent = OmgContent.decode(new Uint8Array(arrayBuffer));
            }
        } catch (err) {
            console.log("Failed to load " + event.data.url + "  Server responded with status: " + err);
            workerResponse.error = err;
        }

        if (omgContent) {
            if (event.version >= 1 && event.jobId !== omgContent.jobId) {
                //If a file failed parsing before it wrote all the files, there may be files from 2 different node trees in the same folder. This lets us know if that has happened.
                console.log("JobId Mismatch! SparseTree jobId: " + event.jobId + " file jobId: " + omgContent.jobId + " url: " + event.data.url);
            }
            // if (event.data.cube_id === "01645" && event.data.lodLevel === 1) {
        
            // }
            const info = createBuffers(omgContent, workerResponse.buffers);

            //workerResponse.size = arrayBuffer.byteLength;
            workerResponse.info = info;
            workerResponse.classNameLookup = omgContent.classNameLookup;
        }

        postMessage(workerResponse, workerResponse.buffers);
    };

    // let testByteSize = 0;
    // let biggestByteSize = 0;
    // var traverseObjectKeys = function(obj) {
    //     for (const key of Object.keys(obj)) {
    //         if (obj[key].length) {
    //             testByteSize += obj[key].length * 8;
    //         }
    //         else {
    //             traverseObjectKeys(obj[key]);
    //         }
    //     }
    // }

    const createBuffers = function(omegaGeometry, buffers) {
        const info = {};

        let byteSize = 0;

        // traverseObjectKeys(omegaGeometry);
        // testByteSize = 0;
        // biggestByteSize = Math.max(biggestByteSize, this.testByteSize);
        // console.log("omg unpacked size: " + (biggestByteSize / (1024*1024)) + " mb");

        info.Debug_OpaqueMeshVertexCount = 0;
        info.Debug_OpaqueMeshIndexCount = 0;
        info.Debug_TransparentMeshVertexCount = 0;
        info.Debug_TransparentMeshIndexCount = 0;
        info.Debug_PointMeshVertexCount = 0;
        info.Debug_PointMeshIndexCount = 0;

        info.Debug_ProductCount = 0;
        info.Debug_TotalGeometryCountInProducts = 0;
        info.Debug_InstancableMeshCount = 0;
        info.Debug_InstancableMeshTotalVertexCount = 0;
        info.Debug_InstancableMeshTotalIndexCount = 0;

        info.TotalVertexCount = 0;
        info.TotalInstancedVertexCount = 0;
        info.DrawCallCount = 0;
        info.ExtraDrawCalls = 0;
        info.fonts = omegaGeometry.fonts;

        // Opaque mergemodel #############################################################################################################################
        let mergedOpaqueMesh = omegaGeometry.mergedOpaqueMesh;

        if ((!omegaGeometry.version || omegaGeometry.version < 3) && mergedOpaqueMesh) {
            //This check is here to counter empty opaque meshes
            //The bug was fixed in the parser in version 3 of the format on 25/02/2022. 
            //This check may be removed if we chose to drop support for files parsed before this date.
            //If a file with the issue is parsed without this check it may negatively impact performance in very large models.

            if (!mergedOpaqueMesh.verticeOffsets.length) {
                mergedOpaqueMesh = null;
            }
        }

        if (mergedOpaqueMesh) {
            info.hasOpaqueMergeModel = true;

            const colors = new Uint8Array(mergedOpaqueMesh.vertices.length);

            let startVertex = 0;
            for (let i = 0; i < mergedOpaqueMesh.verticeOffsets.length; i++) {
                const endVertex = mergedOpaqueMesh.verticeOffsets[i];
          
                const colorIndex = i * 3;
                const r = mergedOpaqueMesh.colors[colorIndex + 0];
                const g = mergedOpaqueMesh.colors[colorIndex + 1];
                const b = mergedOpaqueMesh.colors[colorIndex + 2];

                for (let ii = startVertex; ii < endVertex; ii++) {
                    colors[ii * 3 + 0] = r;
                    colors[ii * 3 + 1] = g;
                    colors[ii * 3 + 2] = b;
                }

                startVertex = endVertex;
            }

            info.TotalVertexCount += mergedOpaqueMesh.vertices.length * 11 / 9 + mergedOpaqueMesh.indices.length * 4;

            

            const vertices = new Float32Array(mergedOpaqueMesh.vertices);
            const indices = vertices.length > 65535 * 3 ? new Uint32Array(mergedOpaqueMesh.indices) : new Uint16Array(mergedOpaqueMesh.indices);
            let bounds;

            //check if we still have 10 bounds values per objectId
            if (mergedOpaqueMesh.bounds.length === (mergedOpaqueMesh.objectIds.length + 1) * 10) {
                bounds = new Float32Array((mergedOpaqueMesh.objectIds.length + 1) * 6);
                const oldBoundsArray = mergedOpaqueMesh.bounds;
                let newIndex = 0;
                for (let oldIndex = 0; oldIndex < oldBoundsArray.length; oldIndex += 10) {
                    bounds[newIndex + 0] = oldBoundsArray[oldIndex + 0];
                    bounds[newIndex + 1] = oldBoundsArray[oldIndex + 1];
                    bounds[newIndex + 2] = oldBoundsArray[oldIndex + 2];
                    bounds[newIndex + 3] = oldBoundsArray[oldIndex + 3];
                    bounds[newIndex + 4] = oldBoundsArray[oldIndex + 4];
                    bounds[newIndex + 5] = oldBoundsArray[oldIndex + 5];
                    newIndex += 6;
                }
            }
            else {
                bounds = new Float32Array(mergedOpaqueMesh.bounds);
            }

            const indexOffsets = indices.length > 65535 ? new Uint32Array(mergedOpaqueMesh.indexOffsets) : new Uint16Array(mergedOpaqueMesh.indexOffsets);//Can't check against 65535 * 3 because indexOffsets are multiplied by 3, this should be fixed on the parser
            const vertexOffsets = vertices.length > 65535 * 3 ? new Uint32Array(mergedOpaqueMesh.verticeOffsets) : new Uint16Array(mergedOpaqueMesh.verticeOffsets);
            const classNames = new Uint32Array(mergedOpaqueMesh.classNames);//Can often be smaller array type
            const objectIds = new Int32Array(mergedOpaqueMesh.objectIds);

            // info.opaqueMergeMesh = {
            //     vertices: vertices,
            //     colors: colors,
            //     indices: indices,
            //     bounds: bounds,
            //     indexOffsets: indexOffsets,
            //     vertexOffsets: vertexOffsets,
            //     classNames: classNames,
            //     objectIds: objectIds,
            // }

            info.Debug_OpaqueMeshVertexCount += vertices.length / 3;
            info.Debug_OpaqueMeshIndexCount += indices.length / 3;

            buffers.push(vertices.buffer);
            buffers.push(colors.buffer);
            buffers.push(indices.buffer);
            buffers.push(bounds.buffer);
            buffers.push(indexOffsets.buffer);
            buffers.push(vertexOffsets.buffer);
            buffers.push(classNames.buffer);
            buffers.push(objectIds.buffer);
            buffers.push(new Uint32Array().buffer);//buffers.push(new Uint32Array(mergedOpaqueMesh.solidIndices).buffer);
            buffers.push(new Uint32Array().buffer);//buffers.push(new Uint32Array(mergedOpaqueMesh.solidIndexOffsets).buffer);

            byteSize -= mergedOpaqueMesh.classNames.length * 4;

            info.DrawCallCount++;
        }

        let lineMeshes = omegaGeometry.mergedLineMesh;
        if (lineMeshes && lineMeshes?.verticeOffsets?.length > 0) {
            info.hasLineMeshes = true;

            const colors = new Uint8Array(lineMeshes.vertices.length);

            let startVertex = 0;
            for (let i = 0; i < lineMeshes.verticeOffsets.length; i++) {
                const endVertex = lineMeshes.verticeOffsets[i];
             
                const colorIndex = i * 3;
                const r = lineMeshes.colors[colorIndex + 0];
                const g = lineMeshes.colors[colorIndex + 1];
                const b = lineMeshes.colors[colorIndex + 2];

                for (let ii = startVertex; ii < endVertex; ii++) {
                    colors[ii * 3 + 0] = r;
                    colors[ii * 3 + 1] = g;
                    colors[ii * 3 + 2] = b;
                }

                startVertex = endVertex;
            }

            info.TotalVertexCount += lineMeshes.vertices.length * 11 / 9;//+ lineMeshes.indices.length * 4;

            const vertices = new Float32Array(lineMeshes.vertices);
            const vertexOffsets = vertices.length > 65535 * 3 ? new Uint32Array(lineMeshes.verticeOffsets) : new Uint16Array(lineMeshes.verticeOffsets);
            const bounds = new Float32Array(lineMeshes.bounds);
            const objectIds = new Int32Array(lineMeshes.objectIds);

            buffers.push(vertices.buffer);
            buffers.push(colors.buffer);
            buffers.push(bounds.buffer);
            buffers.push(vertexOffsets.buffer);
            //buffers.push(new Uint32Array(lineMeshes.classNames).buffer);
            buffers.push(objectIds.buffer);
            // buffers.push(new Uint32Array().buffer);//buffers.push(new Uint32Array(lineMeshes.solidIndices).buffer);
            // buffers.push(new Uint32Array().buffer);//buffers.push(new Uint32Array(lineMeshes.solidIndexOffsets).buffer);

            byteSize -= lineMeshes.classNames.length * 4;
        }

        // transparent mergeModels ###########################################################################################################################
        const mergedTransparentMeshes = omegaGeometry.mergedTransparentMeshes;

        if (mergedTransparentMeshes && mergedTransparentMeshes.length) {
            info.transparentMergeModelCount = mergedTransparentMeshes.length;
            info.hasTransparentMergeMesh = true;

            for (let i = 0; i < mergedTransparentMeshes.length; i++) {
                const mesh = mergedTransparentMeshes[i];

                if (!omegaGeometry.version || omegaGeometry.version < 2) {
                    //This check is here to counter this issue: https://omega.omega365.com/workflow-itemregister/workflow-item?ID=86102
                    //The bug was fixed in the parser in version 2 of the format on 10/02/2022. 
                    //This check may be removed if we chose to drop support for files parsed before this date.
                    //If a file with the issue is parsed without this check it may negatively impact performance in very large models.

                    if (!mesh.verticeOffsets.length) {
                        info.transparentMergeModelCount--;
                        continue;
                    }
                }

                const byteColors = new Uint8Array(mesh.vertices.length);
                const mergeModelAlphas = new Uint8Array(mesh.vertices.length / 3);

                let startVertex = 0;
                for (let j = 0; j < mesh.verticeOffsets.length; j++) {
                    const endVertex = mesh.verticeOffsets[j];
                
                    mergeModelAlphas.fill(mesh.alpha[j], startVertex, endVertex);

                    const colorIndex = j * 3;
                    const r = mesh.colors[colorIndex + 0];
                    const g = mesh.colors[colorIndex + 1];
                    const b = mesh.colors[colorIndex + 2];

                    for (let ii = startVertex; ii < endVertex; ii++) {
                        byteColors[ii * 3 + 0] = r;
                        byteColors[ii * 3 + 1] = g;
                        byteColors[ii * 3 + 2] = b;
                    }

                    startVertex = endVertex;
                }

                info.TotalVertexCount += mesh.vertices.length * 11 / 9 + mesh.indices.length * 4;

                const vertices = new Float32Array(mesh.vertices);
                const indices = vertices.length > 65535 * 3 ? new Uint32Array(mesh.indices) : new Uint16Array(mesh.indices);

                info.Debug_TransparentMeshVertexCount += vertices.length / 3;
                info.Debug_TransparentMeshIndexCount += indices.length / 3;

                let bounds;
                //check if we still have 10 bounds values per objectId
                if (mesh.bounds.length === (mesh.objectIds.length + 1) * 10) {
                    bounds = new Float32Array((mesh.objectIds.length + 1) * 6);
                    const oldBoundsArray = mesh.bounds;
                    let newIndex = 0;
                    for (let oldIndex = 0; oldIndex < oldBoundsArray.length; oldIndex += 10) {
                        bounds[newIndex + 0] = oldBoundsArray[oldIndex + 0];
                        bounds[newIndex + 1] = oldBoundsArray[oldIndex + 1];
                        bounds[newIndex + 2] = oldBoundsArray[oldIndex + 2];
                        bounds[newIndex + 3] = oldBoundsArray[oldIndex + 3];
                        bounds[newIndex + 4] = oldBoundsArray[oldIndex + 4];
                        bounds[newIndex + 5] = oldBoundsArray[oldIndex + 5];
                        newIndex += 6;
                    }
                }
                else {
                    bounds = new Float32Array(mesh.bounds);
                }

                const indexOffsets = indices.length > 65535 ? new Uint32Array(mesh.indexOffsets) : new Uint16Array(mesh.indexOffsets); //Can't check against 65535 * 3 because indexOffsets are multiplied by 3, this should be fixed on the parser

                buffers.push(vertices.buffer);
                buffers.push(byteColors.buffer);
                buffers.push(mergeModelAlphas.buffer);   
                buffers.push(indices.buffer);
                buffers.push(bounds.buffer);
                buffers.push(indexOffsets.buffer);
                buffers.push(vertices.length > 65535 * 3 ? new Uint32Array(mesh.verticeOffsets).buffer : new Uint16Array(mesh.verticeOffsets).buffer);
                buffers.push(new Uint32Array(mesh.classNames).buffer);
                buffers.push(new Int32Array(mesh.objectIds).buffer);
                buffers.push(new Uint32Array().buffer);//buffers.push(new Uint32Array(mesh.solidIndices).buffer);
                buffers.push(new Uint32Array().buffer);//buffers.push(new Uint32Array(mesh.solidIndexOffsets).buffer);

                byteSize -= mesh.classNames.length * 4;
       
                info.DrawCallCount++;
            }
        }
        else {
            info.transparentMergeModelCount = 0;
        }

        // text mergeModels ###########################################################################################################################
        const mergedTextMeshes = omegaGeometry.mergedTextMesh;

        if (mergedTextMeshes && mergedTextMeshes.length) {
            info.textMergeModelCount = mergedTextMeshes.length;

            for (let i = 0; i < mergedTextMeshes.length; i++) {
                const mesh = mergedTextMeshes[i];

                const byteColors = new Uint8Array(mesh.vertices.length);

                let startVertex = 0;
                for (let j = 0; j < mesh.verticeOffsets.length; j++) {
                    const endVertex = mesh.verticeOffsets[j];
                
                    const colorIndex = j * 3;
                    const r = mesh.colors[colorIndex + 0];
                    const g = mesh.colors[colorIndex + 1];
                    const b = mesh.colors[colorIndex + 2];

                    for (let ii = startVertex; ii < endVertex; ii++) {
                        byteColors[ii * 3 + 0] = r;
                        byteColors[ii * 3 + 1] = g;
                        byteColors[ii * 3 + 2] = b;
                    }

                    startVertex = endVertex;
                }

                info.TotalVertexCount += mesh.vertices.length * 11 / 9 + mesh.indices.length * 4;

                buffers.push(new Float32Array(mesh.vertices).buffer);
                buffers.push(byteColors.buffer);
                buffers.push(new Float32Array(mesh.uvs).buffer);
                if (mesh.vertices.length > 65535 * 3) {
                    buffers.push(new Uint32Array(mesh.indices).buffer);
                }
                else {
                    buffers.push(new Uint16Array(mesh.indices).buffer);
                }

                //check if we still have 10 bounds values per objectId
                if (mesh.bounds.length === (mesh.objectIds.length + 1) * 10) {
                    const newBoundsArray = new Float32Array((mesh.objectIds.length + 1) * 6);
                    const oldBoundsArray = mesh.bounds;
                    let newIndex = 0;
                    for (let oldIndex = 0; oldIndex < oldBoundsArray.length; oldIndex += 10) {
                        newBoundsArray[newIndex + 0] = oldBoundsArray[oldIndex + 0];
                        newBoundsArray[newIndex + 1] = oldBoundsArray[oldIndex + 1];
                        newBoundsArray[newIndex + 2] = oldBoundsArray[oldIndex + 2];
                        newBoundsArray[newIndex + 3] = oldBoundsArray[oldIndex + 3];
                        newBoundsArray[newIndex + 4] = oldBoundsArray[oldIndex + 4];
                        newBoundsArray[newIndex + 5] = oldBoundsArray[oldIndex + 5];
                        newIndex += 6;
                    }
                    buffers.push(newBoundsArray.buffer);
                }
                else {
                    buffers.push(new Float32Array(mesh.bounds).buffer);
                }
             
                buffers.push(mesh.indices.length > 65535 ? new Uint32Array(mesh.indexOffsets).buffer : new Uint16Array(mesh.indexOffsets).buffer); //Can't check against 65535 * 3 because indexOffsets are multiplied by 3, this should be fixed on the parser
                buffers.push(mesh.vertices.length > 65535 * 3 ? new Uint32Array(mesh.verticeOffsets).buffer : new Uint16Array(mesh.verticeOffsets).buffer);
                buffers.push(new Uint32Array(mesh.classNames).buffer);
                buffers.push(new Int32Array(mesh.objectIds).buffer);
                buffers.push(new Uint32Array().buffer);//buffers.push(new Uint32Array(mesh.solidIndices).buffer);
                buffers.push(new Uint32Array().buffer);//buffers.push(new Uint32Array(mesh.solidIndexOffsets).buffer);

                byteSize -= mesh.classNames.length * 4;
       
                info.DrawCallCount++;
            }
        }
        else {
            info.textMergeModelCount = 0;
        }


        // mergePoints ###########################################################################################################################
        const mergedPointMesh = omegaGeometry.mergedPointMesh;

        if ( mergedPointMesh ) {
            info.hasMergePoints = true;
        
            const colors = new Uint8Array( mergedPointMesh.vertices.length );

            let startVertex = 0;
            for ( let i = 0; i < mergedPointMesh.verticeOffsets.length; i++ ) {
                const endVertex = mergedPointMesh.verticeOffsets[i];

                const colorIndex = i*3;
                const r = mergedPointMesh.colors[ colorIndex + 0 ];
                const g = mergedPointMesh.colors[ colorIndex + 1 ];
                const b = mergedPointMesh.colors[ colorIndex + 2 ];

                for ( let ii = startVertex; ii < endVertex; ii++ ) {
                    colors[ii * 3 + 0] = r;
                    colors[ii * 3 + 1] = g;
                    colors[ii * 3 + 2] = b;
                }

                startVertex = endVertex;
            }

            info.TotalVertexCount += mergedPointMesh.vertices.length * 11 / 9 + mergedPointMesh.indices.length * 4; // what?

            const vertices = new Float32Array( mergedPointMesh.vertices );
            const indices = vertices.length > 65535 * 3 ? new Uint32Array( mergedPointMesh.indices ) : new Uint16Array( mergedPointMesh.indicies );
            const bounds = new Float32Array( mergedPointMesh.bounds );
            const indexOffsets = indices.length > 65535 ? new Uint32Array( mergedPointMesh.indexOffsets ) : new Uint16Array( mergedPointMesh.indexOffsets ); // Can't check against 65535 * 3 because indexOffsets are multiplied by 3, this should be fixed on the parser
            const vertexOffsets = vertices.length > 65535 * 3 ? new Uint32Array( mergedPointMesh.verticeOffsets ) : new Uint16Array( mergedPointMesh.verticeOffsets );
            const classNames = new Uint32Array( mergedPointMesh.classNames ); 
            const objectIds = new Int32Array( mergedPointMesh.objectIds );

            info.pointMergeMesh = { vertices: vertices, colors: colors, indices: indices, bounds: bounds, indexOffsets: indexOffsets, vertexOffsets: vertexOffsets, classNames: classNames, objectIds: objectIds }

            info.Debug_PointMeshVertexCount += vertices.length / 3;
            info.Debug_PointMeshIndexCount += indices.length / 3;

            buffers.push( vertices.buffer, colors.buffer, bounds.buffer, vertexOffsets.buffer, objectIds.buffer );

            byteSize -= mergedPointMesh.classNames.length * 4;

            info.DrawCallCount++;
        }


        //Instanced meshes ###################################################################################################################################
        let instancedOpaqueMeshCount = omegaGeometry.instancesNumOpaque;
        let instancedTransparentMeshCount = omegaGeometry.instancesNumTransparent;

        
        if (omegaGeometry.version < 5) { //ver 5 was added 11/05/2022
            instancedOpaqueMeshCount = 0;
            instancedTransparentMeshCount = 0;
            for (var i = 0; i < omegaGeometry.prodInstances.length; i++) {
                var prodInstance = omegaGeometry.prodInstances[i];
                var geometries = prodInstance.geometries;
                for (var j = 0; j < geometries.length; j++) {
                    if (geometries[j].color[3] === 255) {
                        instancedOpaqueMeshCount++;
                    }
                    else {
                        instancedTransparentMeshCount++;
                    }
                }
            }
        }

        const instancesOpaqueTotalVertices = omegaGeometry.instancesOpaqueTotalVertices;
        const instancesOpaqueTotalIndices = omegaGeometry.instancesOpaqueTotalIndices;
        //const instancesOpaqueTotalSolids = omegaGeometry.instancesOpaqueTotalSolids;

        const instancesTransparentTotalVertices = omegaGeometry.instancesTransparentTotalVertices;
        const instancesTransparentTotalIndices = omegaGeometry.instancesTransparentTotalIndices;
        //const instancesTransparentTotalSolids = omegaGeometry.instancesTransparentTotalSolids;

        const instanceMeshes = omegaGeometry.instanceMeshes;
        const prodInstances = omegaGeometry.prodInstances;


        // if (instanceMeshes.length > 0) {
        //     let hh = 0;
        //     for (const m of instanceMeshes) {
        //         hh += m.vertices.length + m.indices.length;
        //     }
        //     console.log(hh, instanceMeshes);
        // }


        const instancedOpaque_Vertices = new Float32Array(instancesOpaqueTotalVertices * 3);
        const instancedOpaque_Indices = (instancesOpaqueTotalVertices > 65535) ? new Uint32Array(instancesOpaqueTotalIndices) : new Uint16Array(instancesOpaqueTotalIndices);
        const instancedOpaque_Bounds = new Float32Array((instancedOpaqueMeshCount + 1) * 6);
        const instancedOpaque_IndexOffsets = (instancesOpaqueTotalIndices > 65535) ? new Uint32Array(instancedOpaqueMeshCount) : new Uint16Array(instancedOpaqueMeshCount);
        const instancedOpaque_VertexOffsets = (instancesOpaqueTotalVertices > 65535) ? new Uint32Array(instancedOpaqueMeshCount) : new Uint16Array(instancedOpaqueMeshCount);
        const instancedOpaque_ByteColors = new Uint8Array(instancesOpaqueTotalVertices * 3);
        const instancedOpaque_ClassNames = new Uint32Array(instancedOpaqueMeshCount);
        const instancedOpaque_ObjectIds = new Int32Array(instancedOpaqueMeshCount);
        //const instancedOpaque_Solids = new Uint32Array(instancesOpaqueTotalSolids);
        //const instancedOpaque_SolidOffsets = new Uint32Array(instancedOpaqueMeshCount);

        const instancedTransparent_Vertices = new Float32Array(instancesTransparentTotalVertices * 3);
        const instancedTransparent_Indices = (instancesTransparentTotalVertices > 65535) ? new Uint32Array(instancesTransparentTotalIndices) : new Uint16Array(instancesTransparentTotalIndices);
        const instancedTransparent_Bounds = new Float32Array((instancedTransparentMeshCount + 1) * 6);
        const instancedTransparent_IndexOffsets = (instancesTransparentTotalIndices > 65535) ? new Uint32Array(instancedTransparentMeshCount) : new Uint16Array(instancedTransparentMeshCount);
        const instancedTransparent_VertexOffsets = (instancesTransparentTotalVertices > 65535) ? new Uint32Array(instancedTransparentMeshCount) : new Uint16Array(instancedTransparentMeshCount);
        const instancedTransparent_Alpha = new Uint8Array(instancesTransparentTotalVertices);
        const instancedTransparent_ByteColors = new Uint8Array(instancesTransparentTotalVertices * 3);
        const instancedTransparent_ClassNames = new Uint32Array(instancedTransparentMeshCount);
        const instancedTransparent_ObjectIds = new Int32Array(instancedTransparentMeshCount);
        //const instancedTransparent_Solids = new Uint32Array(instancesTransparentTotalSolids);
        //const instancedTransparent_SolidOffsets = new Uint32Array(instancedTransparentMeshCount);


        let opaqueMeshIndex = 0;
        let transparentMeshIndex = 0;
        let opaqueStartVertex = 0;
        let opaqueStartIndex = 0;
        //let opaqueStartSolid = 0;
        let transparentStartVertex = 0;
        let transparentStartIndex = 0;
        //let transparentStartSolid = 0;

        let mergedOpaqueMinX = Infinity;
        let mergedOpaqueMinY = Infinity;
        let mergedOpaqueMinZ = Infinity;
        let mergedOpaqueMaxX = -Infinity;
        let mergedOpaqueMaxY = -Infinity;
        let mergedOpaqueMaxZ = -Infinity;

        let mergedTransparentMinX = Infinity;
        let mergedTransparentMinY = Infinity;
        let mergedTransparentMinZ = Infinity;
        let mergedTransparentMaxX = -Infinity;
        let mergedTransparentMaxY = -Infinity;
        let mergedTransparentMaxZ = -Infinity;

        const USED_MESHES = new Set();
        const USED_MESHES_2 = new Set();

        info.Debug_ProductCount += prodInstances.length;
        info.Debug_InstancableMeshCount += instanceMeshes.length;
        for (let i = 0; i < instanceMeshes.length; i++) {
            info.Debug_InstancableMeshTotalVertexCount += instanceMeshes[i].vertices.length / 3;
            info.Debug_InstancableMeshTotalIndexCount += instanceMeshes[i].indices.length / 3;
        }

        for (let i = 0; i < prodInstances.length; i++) {
            const prodInstance = prodInstances[i];
            const geometries = prodInstance.geometries;
            const bounds = prodInstance.bounds;
            const className = prodInstance.className;
            const objectId = prodInstance.objectId;

            const overrideOpacity = prodInstance.overrideOpacity;
            const tintColor = prodInstance.tintColor;
            const tintColorStrength = prodInstance.tintColorStrength;
            const oneMinusTintColorStrength = 1 - tintColorStrength;

            const applyColorTint = (omegaGeometry.version && omegaGeometry.version >= 5) && (tintColorStrength > 0 || overrideOpacity > 0);

            let minX = bounds[0];
            let minY = bounds[1];
            let minZ = bounds[2];
            let maxX = bounds[3];
            let maxY = bounds[4];
            let maxZ = bounds[5];

            info.Debug_TotalGeometryCountInProducts += geometries.length;

            for (let j = 0; j < geometries.length; j++) {
                const instanceGeometry = geometries[j];
                const meshIndex = instanceGeometry.meshId;
                const color = instanceGeometry.color;
                const matrix = instanceGeometry.matrix;

                const mesh = instanceMeshes[meshIndex];
                const vertices = mesh.vertices;
                const indices = mesh.indices;
                //const solids = mesh.solidIndices;

                // if (vertices.length === 0) {
                //     debugger;
                // }
                // if (indices.length === 0) {
                //     debugger;
                // }

                if (USED_MESHES.has(meshIndex)) {

                    if (!USED_MESHES_2.has(meshIndex)) {
                        USED_MESHES_2.add(meshIndex);
                        info.ExtraDrawCalls++;
                    }
                    info.TotalInstancedVertexCount += mesh.vertices.length * 11 / 9 + mesh.indices.length * 4;
                }
                else {
                    USED_MESHES.add(meshIndex);
                    info.TotalVertexCount += mesh.vertices.length * 11 / 9 + mesh.indices.length * 4;
                }

                if (applyColorTint) {
                    if (overrideOpacity > 0) {
                        color[3] = Math.floor(overrideOpacity * 255);
                    }
                    if (tintColorStrength > 0) {
                        if (tintColorStrength >= 1) {
                            color[0] = tintColor[0];
                            color[1] = tintColor[1];
                            color[2] = tintColor[2];
                        }
                        else {
                            color[0] = Math.floor(color[0] * oneMinusTintColorStrength + tintColor[0] * tintColorStrength);
                            color[1] = Math.floor(color[1] * oneMinusTintColorStrength + tintColor[1] * tintColorStrength);
                            color[2] = Math.floor(color[2] * oneMinusTintColorStrength + tintColor[2] * tintColorStrength);
                        }
                    }
                }

                const r = color[0];
                const g = color[1];
                const b = color[2];

                if (color[3] === 255) {
                    //Opaque
                    for (let k = 0, h = opaqueStartVertex * 3; k < vertices.length; k+=3, h+=3) {
                        const x = vertices[k];
                        const y = vertices[k + 1];
                        const z = vertices[k + 2];

                        const w = 1 / (matrix[3] * x + matrix[7] * y + matrix[11] * z + matrix[15]);

                        const pX = (matrix[0] * x + matrix[4] * y + matrix[8] * z + matrix[12]) * w;
                        const pY = (matrix[1] * x + matrix[5] * y + matrix[9] * z + matrix[13]) * w;
                        const pZ = (matrix[2] * x + matrix[6] * y + matrix[10] * z + matrix[14]) * w;

                        instancedOpaque_Vertices[h    ] = pX;
                        instancedOpaque_Vertices[h + 1] = pY;
                        instancedOpaque_Vertices[h + 2] = pZ;
                    }

                    for (let k = 0, h = opaqueStartIndex; k < indices.length; k++, h++) {
                        instancedOpaque_Indices[h] = indices[k] + opaqueStartVertex;
                    }

                    // for(let k = 0, h = opaqueStartSolid; k < solids.length; k++, h++){
                    //     instancedOpaque_Solids[h] = solids[k];
                    // }

                    const endIndex = opaqueStartIndex + indices.length;
                    const endVertex = opaqueStartVertex + vertices.length / 3;
                    //const endSolids = opaqueStartSolid + solids.length;

                    for (let ii = opaqueStartVertex; ii < endVertex; ii++) {
                        instancedOpaque_ByteColors[ii * 3 + 0] = r;
                        instancedOpaque_ByteColors[ii * 3 + 1] = g;
                        instancedOpaque_ByteColors[ii * 3 + 2] = b;
                    }

                    const boundsIndex = opaqueMeshIndex * 6;
                    instancedOpaque_Bounds[boundsIndex    ] = minX;
                    instancedOpaque_Bounds[boundsIndex + 1] = minY;
                    instancedOpaque_Bounds[boundsIndex + 2] = minZ;
                    instancedOpaque_Bounds[boundsIndex + 3] = maxX;
                    instancedOpaque_Bounds[boundsIndex + 4] = maxY;
                    instancedOpaque_Bounds[boundsIndex + 5] = maxZ;
     
                    instancedOpaque_IndexOffsets[opaqueMeshIndex] = endIndex;
                    instancedOpaque_VertexOffsets[opaqueMeshIndex] = endVertex;
                    //instancedOpaque_SolidOffsets[opaqueMeshIndex] = endSolids;
                    instancedOpaque_ClassNames[opaqueMeshIndex] = className;
                    instancedOpaque_ObjectIds[opaqueMeshIndex] = objectId;
                    

                    minX > mergedOpaqueMinX || (mergedOpaqueMinX = minX);
                    maxX < mergedOpaqueMaxX || (mergedOpaqueMaxX = maxX);
                    minY > mergedOpaqueMinY || (mergedOpaqueMinY = minY);
                    maxY < mergedOpaqueMaxY || (mergedOpaqueMaxY = maxY);
                    minZ > mergedOpaqueMinZ || (mergedOpaqueMinZ = minZ);
                    maxZ < mergedOpaqueMaxZ || (mergedOpaqueMaxZ = maxZ);

                    opaqueStartIndex = endIndex;
                    opaqueStartVertex = endVertex;
                    //opaqueStartSolid = endSolids;
                    opaqueMeshIndex++;

                } else {
                    //Transparent
                    for (let k = 0, h = transparentStartVertex * 3; k < vertices.length; k+=3, h+=3) {
                        const x = vertices[k];
                        const y = vertices[k + 1];
                        const z = vertices[k + 2];

                        const w = 1 / (matrix[3] * x + matrix[7] * y + matrix[11] * z + matrix[15]);

                        const pX = (matrix[0] * x + matrix[4] * y + matrix[8] * z + matrix[12]) * w;
                        const pY = (matrix[1] * x + matrix[5] * y + matrix[9] * z + matrix[13]) * w;
                        const pZ = (matrix[2] * x + matrix[6] * y + matrix[10] * z + matrix[14]) * w;

                        instancedTransparent_Vertices[h    ] = pX;
                        instancedTransparent_Vertices[h + 1] = pY;
                        instancedTransparent_Vertices[h + 2] = pZ;
                    }
 
                    for (let k = 0, h = transparentStartIndex; k < indices.length; k++, h++) {
                        instancedTransparent_Indices[h] = indices[k] + transparentStartVertex;
                    }

                    // for(let k = 0, h = transparentStartSolid; k < solids.length; k++, h++){
                    //     instancedTransparent_Solids[h] = solids[k];
                    // }

                    const endIndex = transparentStartIndex + indices.length;
                    const endVertex = transparentStartVertex + vertices.length / 3;
                    //const endSolids = transparentStartSolid + solids.length;

                    instancedTransparent_Alpha.fill(color[3], transparentStartVertex, endVertex);

                    for (let ii = transparentStartVertex; ii < endVertex; ii++) {
                        instancedTransparent_ByteColors[ii * 3 + 0] = r;
                        instancedTransparent_ByteColors[ii * 3 + 1] = g;
                        instancedTransparent_ByteColors[ii * 3 + 2] = b;
                    }

                    const boundsIndex = transparentMeshIndex * 6;
                    instancedTransparent_Bounds[boundsIndex    ] = minX;
                    instancedTransparent_Bounds[boundsIndex + 1] = minY;
                    instancedTransparent_Bounds[boundsIndex + 2] = minZ;
                    instancedTransparent_Bounds[boundsIndex + 3] = maxX;
                    instancedTransparent_Bounds[boundsIndex + 4] = maxY;
                    instancedTransparent_Bounds[boundsIndex + 5] = maxZ;

                    instancedTransparent_IndexOffsets[transparentMeshIndex] = endIndex;
                    instancedTransparent_VertexOffsets[transparentMeshIndex] = endVertex;
                    //instancedTransparent_SolidOffsets[transparentMeshIndex] = endSolids;
                    instancedTransparent_ClassNames[transparentMeshIndex] = className;
                    instancedTransparent_ObjectIds[transparentMeshIndex] = objectId;

                    minX > mergedTransparentMinX || (mergedTransparentMinX = minX);
                    maxX < mergedTransparentMaxX || (mergedTransparentMaxX = maxX);
                    minY > mergedTransparentMinY || (mergedTransparentMinY = minY);
                    maxY < mergedTransparentMaxY || (mergedTransparentMaxY = maxY);
                    minZ > mergedTransparentMinZ || (mergedTransparentMinZ = minZ);
                    maxZ < mergedTransparentMaxZ || (mergedTransparentMaxZ = maxZ);

                    transparentStartIndex = endIndex;
                    transparentStartVertex = endVertex;
                    //transparentStartSolid = endSolids;
                    transparentMeshIndex++;
                }
            } 
        }
        if (instancedTransparent_ObjectIds.length > 0) {
            //info.hasTransparentInstancedMeshes = true;
            info.transparentMergeModelCount++; //simply add this as an extra transparent merge model

            const boundsIndex = instancedTransparent_Bounds.length - 6;
            instancedTransparent_Bounds[boundsIndex    ] = mergedTransparentMinX;
            instancedTransparent_Bounds[boundsIndex + 1] = mergedTransparentMinY;
            instancedTransparent_Bounds[boundsIndex + 2] = mergedTransparentMinZ;
            instancedTransparent_Bounds[boundsIndex + 3] = mergedTransparentMaxX;
            instancedTransparent_Bounds[boundsIndex + 4] = mergedTransparentMaxY;
            instancedTransparent_Bounds[boundsIndex + 5] = mergedTransparentMaxZ;

            buffers.push(instancedTransparent_Vertices.buffer);
            buffers.push(instancedTransparent_ByteColors.buffer);
            buffers.push(instancedTransparent_Alpha.buffer);
            buffers.push(instancedTransparent_Indices.buffer);
            buffers.push(instancedTransparent_Bounds.buffer);
            buffers.push(instancedTransparent_IndexOffsets.buffer);
            buffers.push(instancedTransparent_VertexOffsets.buffer);
            buffers.push(instancedTransparent_ClassNames.buffer);
            buffers.push(instancedTransparent_ObjectIds.buffer);
            buffers.push(new Uint32Array().buffer);//buffers.push(instancedTransparent_Solids.buffer);
            buffers.push(new Uint32Array().buffer);//buffers.push(instancedTransparent_SolidOffsets.buffer);

            info.DrawCallCount++;
        }

        if (instancedOpaque_ObjectIds.length > 0) {
            info.hasOpaqueInstancedMeshes = true;

            const boundsIndex = instancedOpaque_Bounds.length - 6;
            instancedOpaque_Bounds[boundsIndex    ] = mergedOpaqueMinX;
            instancedOpaque_Bounds[boundsIndex + 1] = mergedOpaqueMinY;
            instancedOpaque_Bounds[boundsIndex + 2] = mergedOpaqueMinZ;
            instancedOpaque_Bounds[boundsIndex + 3] = mergedOpaqueMaxX;
            instancedOpaque_Bounds[boundsIndex + 4] = mergedOpaqueMaxY;
            instancedOpaque_Bounds[boundsIndex + 5] = mergedOpaqueMaxZ;

            buffers.push(instancedOpaque_Vertices.buffer);
            buffers.push(instancedOpaque_ByteColors.buffer);
            buffers.push(instancedOpaque_Indices.buffer);
            buffers.push(instancedOpaque_Bounds.buffer);
            buffers.push(instancedOpaque_IndexOffsets.buffer);
            buffers.push(instancedOpaque_VertexOffsets.buffer);
            buffers.push(instancedOpaque_ClassNames.buffer);
            buffers.push(instancedOpaque_ObjectIds.buffer);
            buffers.push(new Uint32Array().buffer);//buffers.push(instancedOpaque_Solids.buffer);
            buffers.push(new Uint32Array().buffer);//buffers.push(instancedOpaque_SolidOffsets.buffer);

            info.DrawCallCount++;
        }

        for (let i = 0; i < buffers.length; i++) {
            byteSize += buffers[i].byteLength;
        }

        // for (let i = 0; i < instanceMeshes.length; i++) {
        //     if (!USED_MESHES.has(i)) {
        //         //console.log("TTT 0 Instance Mesh is not even used once");
        //     }
            
        //     if (!USED_MESHES_2.has(i)) {
        //         //console.log("TTT 1 Instance Mesh is used only once");
        //     }
        //     else {
        //         //console.log("TTT 2 Instance Mesh is used alot");
        //     }
        // }
        
        byteSize -= instancedOpaque_ClassNames.buffer.byteLength;
        byteSize -= instancedTransparent_ClassNames.buffer.byteLength;

        info.byteSize = byteSize;

        info.TotalVertexCount += info.TotalInstancedVertexCount;

        return info;
    }
}