/**********************************************************************/
/* ----        IMPORTANT! Read before making any changes         ---- */
/**********************************************************************/
/* ----           This file is part of a set of files            ---- */
/* ----            Any changes here MUST be added to:            ---- */
/**********************************************************************/
/* - o365.pwa.declaration.shared.dexie.databases.O365PWAGlobal.d.ts   */
/* - o365.pwa.modules.client.dexie.databases.O365PWAGlobal.ts         */
/* - o365.pwa.modules.sw.dexie.databases.O365PWAGlobal.ts             */
/**********************************************************************/

import Dexie from 'dexie';
import FileStoreRecord from 'o365.pwa.modules.client.dexie.objectStores.FileStoreFile.ts';

import type { IFileStoreRecordOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';

export class O365PWAGlobal extends Dexie {
    public fileStore!: Dexie.Table<FileStoreRecord, number>;
    
    constructor() {
        super('O365_PWA_GLOBAL');

        super.version(2).stores({
            fileStore: FileStoreRecord.fileStoreRecordDexieSchema
        });
        
        this.fileStore.mapToClass(FileStoreRecord);
    }

    public async retrieveFileStoreRecord(primKey: string, appId: string): Promise<FileStoreRecord | null> {
        return await this.fileStore.get([primKey, appId]) ?? null;
    }

    public async retrieveFileStoreRecords(primaryKeys: Array<[ primKey: string, appId: string ]>): Promise<Array<FileStoreRecord | null>> {
        const fileStoreRecords: Array<FileStoreRecord | undefined | null> = await this.fileStore.bulkGet(primaryKeys) as Array<FileStoreRecord | undefined>;

        for (let i = 0; i < fileStoreRecords.length; i++) {
            if (fileStoreRecords[i] === undefined) {
                fileStoreRecords[i] = null;
            }
        }

        return fileStoreRecords as Array<FileStoreRecord | null>;
    }

    public async createFileStoreRecord(fileStoreRecordOptions: IFileStoreRecordOptions): Promise<void> {
        let fileStoreRecord: FileStoreRecord;

        if (fileStoreRecordOptions.data instanceof Blob) {
            fileStoreRecord = await FileStoreRecord.fromBlob(fileStoreRecordOptions as IFileStoreRecordOptions<Blob>)
        } else if (fileStoreRecordOptions.data instanceof ArrayBuffer) {
            fileStoreRecord = new FileStoreRecord(fileStoreRecordOptions as IFileStoreRecordOptions<ArrayBuffer>)
        } else {
            throw new Error('data must be of type Blob or ArrayBuffer');
        }

        await fileStoreRecord.save();
    }

    public async bulkCreateFileStoreRecords(fileStoreRecordsOptions: Array<IFileStoreRecordOptions>): Promise<void> {
        const fileStoreRecords = fileStoreRecordsOptions.map(async (fileStoreRecordOptions) => {
            let fileStoreRecord: FileStoreRecord;

            if (fileStoreRecordOptions.data instanceof Blob) {
                fileStoreRecord = await FileStoreRecord.fromBlob(fileStoreRecordOptions as IFileStoreRecordOptions<Blob>)
            } else if (fileStoreRecordOptions.data instanceof ArrayBuffer) {
                fileStoreRecord = new FileStoreRecord(fileStoreRecordOptions as IFileStoreRecordOptions<ArrayBuffer>)
            } else {
                throw new Error('data must be of type Blob or ArrayBuffer');
            }

            return fileStoreRecord
        });

        await this.bulkUpdateFileStoreRecords(await Promise.all(fileStoreRecords));
    }

    public async updateFileStoreRecord(fileStoreRecord: FileStoreRecord): Promise<void> {
        await this.fileStore.put(fileStoreRecord);
    }

    public async bulkUpdateFileStoreRecords(fileStoreRecords: Array<FileStoreRecord>): Promise<void> {
        await this.fileStore.bulkPut(fileStoreRecords);
    }

    public async destroyFileStoreRecord(primKey: string, appId: string): Promise<void> {
        await this.fileStore.delete(primKey + appId);
    }

    public async bulkDestroyFileStoreRecords(primaryKeys: Array<[ primKey: string, appId: string ]>): Promise<void> {
        await this.fileStore.bulkDelete(primaryKeys);
    }

    public async bulkDestroyFileStoreRecordsByAppId(appID: string): Promise<void> {
        const fileStore = await this.fileStore.where("appID").equals(appID);
        await fileStore.delete();
    }
}

export const o365PWAGlobal = new O365PWAGlobal();

export default o365PWAGlobal;
