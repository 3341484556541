<template>
    <div class="row row-container">
        <div class="col-12 row-container">
            <div class="row">
                <div class="col-12">
                    <OPersonsLookup :data-object="dsPersonsLookup" 
                        :bind="addPerson" 
                        noClear
                        multiselect
                        :whereClause="personLkpWhereClause">
                        <template #target="{ target }">
                            <h6 :ref="target" class="d-inline text-primary" style="cursor:pointer" :title="$t('Grants immediate access to the document')"><i class="bi bi-plus-lg"></i> {{$t('Distribute / Grant access to Person')}}</h6>
                        </template>
                        <template #toolbarActions>
                            <OToolbarAction :text="$t('Restrict to users with access')" v-model="restrictToUsersWithAccess" />
                        </template>
                    </OPersonsLookup>

                    <label class="form-check-label d-inline me-1 pt-1 float-end" :title="props.dataObject.current.Confidential ? $t('Persons from Distr. groups are not applicable for confidential documents') : ''">
                        <input class="form-check-input" type="checkbox" value="" v-model="vExcludePersonsFromGroups" :disabled="props.dataObject.current.Confidential">
                        {{$t('Excl. persons from Distr. groups')}}
                    </label>
                </div>
            </div>
            <o-data-grid :data-object="dsDocPersonsAll" hide-grid-menu v-if="!vExcludePersonsFromGroups" style="max-height:400px;">
                <o-column field="Person" width="200" sortable :headerName="$t('Person')">
                    <template #default="{row}">
                         <span v-person-hover="row.Person_ID" :class="{ 'text-danger': !row.HasPrimaryAccess }">{{row.Person}}</span>
                         <span class="float-end" @click="distributeToPerson(row.Person_ID,row.Person)" style="cursor:pointer;" :title="$t('Inform Person')"><i class="bi bi-bell"></i></span>
                    </template>
                </o-column>
                <o-column field="RepresentsCompany" width="200" hide :headerName="$t('Represents Company')"></o-column>
                <o-column field="DistributionSource" width="200" :headerName="$t('Distribution Source')" :headerTitle="$t('Shows from which distribution group(s) the person has been added from (if any)')"></o-column>
                <o-column field="ForReview" width="120" :editable="row => row.ForReviewEditable" :cellTitle="row => !row.ForReviewEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('For Review')" :headerTitle="$t('For user to be added as reviewer on initiate review')"></o-column>
                <o-column field="ForApproval" width="120" :editable="row => row.ForApprovalEditable" :cellTitle="row => !row.ForApprovalEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('For Approval')" :headerTitle="$t('For user to be added as approver on initiate approval')"></o-column>
                <o-column field="Check" width="80" :editable="row => row.CheckEditable" :cellTitle="row => !row.CheckEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('Check')" :headerTitle="$t('For user to be added as checker on initiate check or approval')"></o-column>
                <o-column field="ForInfo" width="80" :editable="row => row.ForInfoEditable" :cellTitle="row => !row.ForInfoEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('For Info')" :headerTitle="$t('For user to receive For Info notifications')"></o-column>
                <o-column field="AllowEdit" width="100" :editable="row => row.AllowEditEditable" :cellTitle="row => !row.AllowEditEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('Allow Edit')" :headerTitle="$t('Allow edit (if user does not already have edit permissions on document)')"></o-column>
                <o-column field="Subscribe" width="100" :editable="row => row.SubscribeEditable" :cellTitle="row => !row.SubscribeEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('Subscribe')" :headerTitle="$t('Will add the document to home page under Documents Subscriptions')"></o-column>
                <o-column field="Redliner" width="100" hide :editable="row => row.RedlinerEditable" :cellTitle="row => !row.RedlinerEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('Redliner')" :headerTitle="$t('User will be added as a Redline Participant when Redlining Process is initiated')"></o-column>
                <o-column field="RedlineVerifier" width="120" hide :editable="row => row.RedlineVerifierEditable" :cellTitle="row => !row.RedlineVerifierEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('Redline Verifier')" :headerTitle="$t('User will be added as a Redline Verifier when Redlining Process is initiated')"></o-column>
                <o-column field="RedlineApprover" width="130" hide :editable="row => row.RedlineApproverEditable" :cellTitle="row => !row.RedlineApproverEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('Redline Approver')" :headerTitle="$t('User will be added as a Redline Approver when Redlining Process is initiated')"></o-column>
                <o-column field="Purpose" width="200" hide :editable="row => row.DocPersonPrimKey && row.PurposeEditable" :cellTitle="row => !row.DocPersonPrimKey || !row.PurposeEditable ? $t('Cannot override purpose from distribution group') : ''" :headerName="$t('Purpose')">

                </o-column>
                <o-column field="OrgUnit" width="150" hide :headerName="$t('Org Unit')"></o-column>
            </o-data-grid>

            <o-data-grid :data-object="dsDocPersonsDocOnly" hide-grid-menu v-else style="max-height:400px;">
                <o-column field="Person" width="200" sortable :headerName="$t('Person')">
                    <template #default="{row}">
                         <span v-person-hover="row.Person_ID" :class="{ 'text-danger': !row.HasPrimaryAccess }">{{row.Person}}</span>
                         <span class="float-end" @click="distributeToPerson(row.Person_ID,row.Person)" style="cursor:pointer;" :title="$t('Inform Person')"><i class="bi bi-bell"></i></span>
                    </template>
                </o-column>
                <o-column field="RepresentsCompany" width="200" hide :headerName="$t('Represents Company')"></o-column>
                <o-column field="ForReview" width="120" editable bulkUpdate :headerName="$t('For Review')" :headerTitle="$t('For user to be added as reviewer on initiate review')"></o-column>
                <o-column field="ForApproval" width="120" editable bulkUpdate :headerName="$t('For Approval')" :headerTitle="$t('For user to be added as approver on initiate approval')"></o-column>
                <o-column field="Check" width="80" editable bulkUpdate :headerName="$t('Check')" :headerTitle="$t('For user to be added as checker on initiate check or approval')"></o-column>
                <o-column field="ForInfo" width="80" editable bulkUpdate :headerName="$t('For Info')" :headerTitle="$t('For user to receive For Info notifications')"></o-column>
                <o-column field="AllowEdit" width="100" editable bulkUpdate :headerName="$t('Allow Edit')" :headerTitle="$t('Allow edit (if user does not already have edit permissions on document)')"></o-column>
                <o-column field="Subscribe" width="100" editable bulkUpdate :headerName="$t('Subscribe')" :headerTitle="$t('Will add the document to home page under Documents Subscriptions')"></o-column>
                <o-column field="Redliner" width="100" editable bulkUpdate hide :headerName="$t('Redliner')" :headerTitle="$t('User will be added as a Redline Participant when Redlining Process is initiated')"></o-column>
                <o-column field="RedlineVerifier" width="120" editable bulkUpdate hide :headerName="$t('Redline Verifier')" :headerTitle="$t('User will be added as a Redline Verifier when Redlining Process is initiated')"></o-column>
                <o-column field="RedlineApprover" width="130" editable bulkUpdate hide :headerName="$t('Redline Approver')" :headerTitle="$t('User will be added as a Redline Approver when Redlining Process is initiated')"></o-column>
                <o-column field="Purpose" width="200" hide editable bulkUpdate :headerName="$t('Purpose')"></o-column>
                <o-column field="OrgUnit" width="150" hide :headerName="$t('Org Unit')"></o-column>
            </o-data-grid>
        </div>
    </div>
</template>

<script setup>
    import { ref,watch,computed,onMounted } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { getOrCreateProcedure, userSession } from 'o365-modules'
    import { OPersonsLookup } from 'o365-system-lookups';
    import { alert } from 'o365-vue-services';
    import { confirm } from 'o365-vue-services'
    import { useDataObjectEventListener } from 'o365-vue-utils';
    import vPersonHover from 'system.libraries.vue.directive.personHover.ts';
    
    const props = defineProps({
        dataObject: Object
    })

    const vExcludePersonsFromGroups = ref(props.dataObject.current.Confidential)

    watch(() => props.dataObject.current.Confidential, () => {
        vExcludePersonsFromGroups.value = props.dataObject.current.Confidential ? props.dataObject.current.Confidential : vExcludePersonsFromGroups.value

    });
    watch(vExcludePersonsFromGroups, () => {
        refreshGrid();
    });

    //TODO: now these seems to refreshed on refreshRow (parent), no need to
    const dsDocPersonsAllDefinition = {
        id: 'dsDocPersonsAll',
        viewName: 'aviw_Arena_DocumentsPersonsAgg',
        appId: props.dataObject.appId,
        uniqueTable:"atbv_Arena_DocumentsPersons",
        allowDelete: true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        disableAutoLoad: false,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "Document_ID", type: "number" },
                {name: "Person_ID", type: "number"},
                {name: "ForReview", type: "bit"},
                {name: "ForApproval", type: "bit"},
                {name: "ForInfo", type: "bit"},
                {name: "Check", type: "bit"},
                {name: "Subscribe", type: "bit"},
                {name: "Person", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "AllowEdit", type: "bit"},
                {name: "Redliner", type: "bit"},
                {name: "RedlineVerifier", type: "bit"},
                {name: "RedlineApprover", type: "bit"},
                {name: "RepresentsCompany", type: "string"},
                {name: "DistributionSource", type: "string"},
                {name: "OrgUnit", type: "string"},
                {name: "HasPrimaryAccess", type: "bit"},
                {name: "ForReviewEditable", type: "bit"},
                {name: "ForApprovalEditable", type: "bit"},
                {name: "ForInfoEditable", type: "bit"},
                {name: "CheckEditable", type: "bit"},
                {name: "SubscribeEditable", type: "bit"},
                {name: "AllowEditEditable", type: "bit"},
                {name: "RedlinerEditable", type: "bit"},
                {name: "RedlineVerifierEditable", type: "bit"},
                {name: "RedlineApproverEditable", type: "bit"},
                {name: "Purpose", type: "string"},
                {name: "PurposeEditable", type: "bit"},
                {name: "AllowEditEditable", type: "bit"},
                {name: "DocPersonPrimKey", type: "string"}
            ]
    }, dsDocPersonsDocOnlyDefinition = {
        id: 'dsDocPersonsDocOnly',
        viewName: 'aviw_Arena_DocumentsPersons',
        appId: props.dataObject.appId,
        uniqueTable:"atbv_Arena_DocumentsPersons",
        allowDelete: true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        disableAutoLoad: false,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "Document_ID", type: "number" },
                {name: "Person_ID", type: "number"},
                {name: "ForReview", type: "bit"},
                {name: "ForApproval", type: "bit"},
                {name: "ForInfo", type: "bit"},
                {name: "Check", type: "bit"},
                {name: "Subscribe", type: "bit"},
                {name: "Person", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "AllowEdit", type: "bit"},
                {name: "Redliner", type: "bit"},
                {name: "RedlineVerifier", type: "bit"},
                {name: "RedlineApprover", type: "bit"},
                {name: "RepresentsCompany", type: "string"},
                {name: "OrgUnit", type: "string"},
                {name: "HasPrimaryAccess", type: "bit"},
                {name: "Purpose", type: "string"}
            ]
    }, dsPersonsLookupDefinition = {
        id: 'dsPersonsLookup',
        viewName: 'aviw_Arena_PersonsLookup',
        appId: props.dataObject.appId,
        //maxRecords: 100,
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: false,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"},
                {name: "MobileNo", type: "string"},
                {name: "Email", type: "string"},
                {name: "Represents", type: "string"}
            ]
    }

    const dsDocPersonsAll = getOrCreateDataObject(dsDocPersonsAllDefinition);
    const dsDocPersonsDocOnly = getOrCreateDataObject(dsDocPersonsDocOnlyDefinition);
    const dsPersonsLookup = getOrCreateDataObject(dsPersonsLookupDefinition);
    
    const restrictToUsersWithAccess = ref(true);
    const personLkpWhereClause = computed(()=>{
        return "AllPersons = " + (restrictToUsersWithAccess.value ? "0" : "1")
    });

    const procDocPersonsSetStn = getOrCreateProcedure({
        id: 'procDocPersonsSetStn',
        procedureName: 'astp_Arena_DocumentsPersonsSetSetting'
    });
    const procDocPerSetPurpose = getOrCreateProcedure({
        id: 'procDocPerSetPurpose',
        procedureName: 'astp_Arena_DocumentsPersonsSetPurpose'
    });
    const procInsertLkpSelection = getOrCreateProcedure({
        id: 'procInsertLkpSelection',
        procedureName: 'astp_Arena_DocumentsInsertLookupSelection'
    });
    const procDocPersonsDelete = getOrCreateProcedure({
        id: 'procDocPersonsDelete',
        procedureName: 'astp_Arena_DocumentsPersonsDelete'
    });
    const procCreateDistr = getOrCreateProcedure({
        id: 'procCreateDistr',
        procedureName: 'astp_Arena_DocumentCreateDistribution'
    });

    function addPerson(rows) {
        const personIDs = rows.map(row => ({ Value: row._item.ID }));

        procInsertLkpSelection.execute({
            Document_ID: props.dataObject.current.ID,
            Ids: personIDs,
            Type: "Persons"
        }).then((res) => {
            refreshGrid();
            // refresh active grid
        });
    }

    useDataObjectEventListener(dsDocPersonsAll, 'BeforeUpdate', (options, item) => {
        options.cancelEvent = true;
    });

    useDataObjectEventListener(dsDocPersonsAll, 'FieldChanged', (pField, pNewValue, pOldValue, pRow) => {
        if (['ForReview', 'ForInfo', 'ForApproval', 'Check', 'Subscribe', 'AllowEdit', 'Redliner', 'RedlineVerifier', 'RedlineApprover'].indexOf(pField) > -1){
            procDocPersonsSetStn.execute({
                Document_ID: pRow.Document_ID,
                Person_ID: pRow.Person_ID,
                Field: pField,
                Value: pNewValue
            }).then((res)=>{
                dsDocPersonsAll.recordSource.refreshRow(pRow.index);
            });
        } else if (pField == "Purpose"){
            docPersonUpdatePurpose(pRow);
        }
    });
    
    useDataObjectEventListener(dsDocPersonsAll, 'BeforeDelete', (options, row) => {
        options.cancelEvent = true;
        if (row.DocPersonPrimKey){

            procDocPersonsDelete.execute({
                Document_ID: row.Document_ID,
                Person_ID: row.Person_ID,
            }).then((res)=>{
                if (row.DistributionSource){
                    dsDocPersonsAll.recordSource.refreshRow(row.index);
                } else {
                    dsDocPersonsAll.load();
                }
            });
        } else {
            alert($t("Cannot delete person from distribution group"), "warning");
        }
    });

    var docPersonsDebounce = {};
    function docPersonUpdatePurpose(pRow){
        var vValue=event.target.value;
        if (docPersonsDebounce.hasOwnProperty(pRow.DocPersonPrimKey)){
            clearTimeout(docPersonsDebounce[pRow.DocPersonPrimKey]);
        }

        docPersonsDebounce[pRow.DocPersonPrimKey] = window.setTimeout(function () {
            procDocPerSetPurpose.execute({
                PrimKey: pRow.DocPersonPrimKey,
                Value: vValue
            }).then((res)=>{
                //dsDocPersonsAll.cancelChanges();
                dsDocPersonsAll.recordSource.refreshRow(pRow.index);
            });
        }, 1000);
    }

    async function distributeToPerson(pPerson_ID, pPerson){
        try {

            await confirm({
                title: $t('Inform Person?'),
                message: $t("Are you sure you would like to inform") + ' ' + pPerson + ' ' + $t("about this document profile?")
            });

            procCreateDistr.execute({
                Document_ID: props.dataObject.current.ID,
                Distribution: "ForUseSingle",
                Person_ID: pPerson_ID
            }).then((res)=>{
                console.log(res);
                alert(res.Table[0].Message, "success", { autohide: true, delay: 3000 });
            });
        } catch (_) {}
    }


    function refreshGrid(){
        if (vExcludePersonsFromGroups.value){ //TODO: only load if change has been made in previous grid view
            dsDocPersonsDocOnly.load();
        } else {
            dsDocPersonsAll.load();
        }
    }
    useDataObjectEventListener(dsDocPersonsDocOnly, 'BeforeDelete', (options, row)=>{
        if (props.dataObject.current.Confidential === true && userSession.personId == row.Person_ID) {
            options.cancelEvent = true;
            confirm({
                title: $t('Inform Person?'),
                message: $t("Are you sure you want to remove yourself from the distribution list? This will remove your access to this document. Confirm to proceed."),
                btnTextOk: $t('Confirm'),
                btnTextCancel: $t('Cancel'),
            }).then(async (result) => {
                if(!result['Canceled']) {
                    procDocPersonsDelete.execute({
                        Document_ID: row.Document_ID,
                        Person_ID: row.Person_ID,
                    }).then(()=>{dsDocPersonsDocOnly.load()})
                }
            }).catch(()=>{});
        }
    })

    onMounted(() => {
        refreshGrid();
    });

    defineExpose({ refreshGrid });
</script>


<style>
    .customInput:focus {
        background:transparent;
        background-color: rgb(var(--bs-body-bg-rgb)) !important;
    }

</style>