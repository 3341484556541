import { StepDefinition, IStepDefinitionOptions, IOfflineStepDefinition, IOnlineStepDefinition, type ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { PropertyConfigSyncProgress, type IPropertyConfigSyncProgressJSON, type IPropertyConfigSyncProgressOptions } from 'o365.pwa.modules.client.steps.PropertyConfigSyncProgress.ts';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { getOrCreateDataObject } from 'o365-dataobject';
import { app } from 'o365-modules';
import { GroupStepDefinition } from 'o365.pwa.modules.client.steps.GroupStepDefinition.ts';
import { DataObjectStepDefinition } from 'o365.pwa.modules.client.steps.DataObjectStepDefinition.ts';

import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import { type SyncType } from "o365.pwa.types.ts";
import type { PropertyConfig } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import type { RecordSourceOptions, DataObjectDefinitionFieldType } from 'o365-dataobject';
import type Database from 'o365.pwa.modules.client.dexie.objectStores.Database2.ts';
import type ObjectStore from 'o365.pwa.modules.client.dexie.objectStores.ObjectStore.ts';
import type Index from 'o365.pwa.modules.client.dexie.objectStores.Index.ts';


export const SystemPropertiesFields = [
    'ID', 'Name', 'Title', 'Caption', 'DataType', 'InputEditor',
    'Config', 'HasLookupValues', 'Group', 'Description', 'WhereClause', 'IsInformation',
    'IsUrl', 'Placeholder', 'Format', 'Unit', 'MasterProperty', 'MasterDetailBinding', 'Rows',
    'Url', 'Tooltip_ID'
] as const;

export interface IPropertyConfigSyncStepDefinition extends IStepDefinitionOptions {
}

export class PropertyConfigSyncStepDefinition extends StepDefinition implements IOfflineStepDefinition<PropertyConfigSyncProgress>, IOnlineStepDefinition<PropertyConfigSyncProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    public readonly IOnlineStepDefinition = 'IOnlineStepDefinition';

    constructor(options: IPropertyConfigSyncStepDefinition) {
        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'PropertyConfigSyncProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.PropertyConfigSyncProgress.vue');
            }
        });
    }


    public toRunStepDefinition(): PropertyConfigSyncStepDefinition {
        return new PropertyConfigSyncStepDefinition({
            stepId: this.stepId,
            dependOnPreviousStep: this.dependOnPreviousStep,
            title: this.title
        });
    }

    generateStepProgress(options?: IPropertyConfigSyncProgressJSON | IPropertyConfigSyncProgressOptions, syncType?: SyncType): PropertyConfigSyncProgress {
        return new PropertyConfigSyncProgress({
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback
        });
    }

    public async syncOnline(options: ISyncOptions<PropertyConfigSyncProgress>): Promise<void> {
        return;
    }

    public async syncOffline(options: ISyncOptions<PropertyConfigSyncProgress>): Promise<void> {
        await this.onSync(options);
    }

    private async onSync(options: ISyncOptions<PropertyConfigSyncProgress> | ISyncOptions<PropertyConfigSyncProgress>): Promise<void> {
        try {
            const getPwaVueAppInstance = options.getPwaVueAppInstance;

            if (getPwaVueAppInstance) {

                const vueApp = getPwaVueAppInstance();

                try {

                    if (!vueApp._context.components[this.vueComponentName]) {
                        const vueComponent = await this.vueComponentImportCallback();

                        vueApp.component(this.vueComponentName, vueComponent.default)
                    }
                } catch (reason) {
                    console.error(reason);
                }
            }


            options.stepProgress.propertyConfigSyncHasStarted = true;

            const propertyConfigs = this.getPropertyConfigs(options.syncProgress.customData.propertyConfigs);
            const viewNames = options.syncProgress.customData.dataObjectPropertyViewNames as Set<string>;

            if (propertyConfigs.size > 0) {
                const bindingWithEditors = this.createPropertybindingWithEditors();

                const dataObjects = this.createPropertyDataObjects(propertyConfigs);

                await this.createObjectStores([
                    ...dataObjects,
                    ...bindingWithEditors,
                ]);

                if (options.indexForPropertyDataObjects && dataObjects.length > 0 && bindingWithEditors[0].dataObject && options.dependencyMapping) {
                    const group = this.createStepDefinitions(dataObjects, bindingWithEditors[0].dataObject, viewNames)

                    const index = options.indexForPropertyDataObjects + 1;

                    const stepProgress = group.generateStepProgress(undefined, options.syncRunDefinition.syncType);

                    // Bump the indexes in dependencyMapping for all entries at and above the insertion index
                    for (const [key, value] of options.dependencyMapping) {
                        if (value >= index) {
                            options.dependencyMapping.set(key, value + 1);
                        }
                    }
                    options.dependencyMapping!.set(group.stepId, index);

                    for (let i = index; i < options.syncRunDefinition.steps.length; i++) {
                        options.syncRunDefinition.steps[i].dependOnPreviousStep.push(group.stepId);
                    }



                    options.syncRunDefinition.steps.splice(index, 0, group);
                    options.syncProgress.resourcesProgress.splice(index, 0, stepProgress);
                }
            }


            options.stepProgress.propertyConfigSyncHasStarted = false;
            options.stepProgress.propertyConfigSyncHasCompleted = true;

        } catch (error: any) {
            if (Array.isArray(error)) {
                for (var e of error) {
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', `${e.ErrorMessage}`));
                }
            } else {
                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', `Try again or contact support if the issue does not get resolved. ${error}`));
            }
            options.stepProgress.propertyConfigSyncHasErrors = true;
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
        }
    }

    private createStepDefinitions(dataObjects: Array<any>, bindingWithEditors: any, viewNames: Set<string>) {
        return new GroupStepDefinition({
            stepId: 'PropertyConfigSyncObjects',
            title: "Property Lookup Sync",
            dependOnPreviousStep: ["_PropertyConfigSyncStepDefinition_"],
            steps: [
                ...dataObjects.map(({ dataObject, _config }) => {
                    const isObjectOrOrgUnit = [
                        "dsO365_OFFLINE_PropertiesLookup_Objects",
                        "dsO365_OFFLINE_PropertiesLookup_OrgUnits"].includes(dataObject.id);
                    return new DataObjectStepDefinition({
                        stepId: `_${dataObject.id}_`,
                        dataObjectId: dataObject.id,
                        title: `Properties - ${dataObject.viewName}`,
                        onBeforeSync: isObjectOrOrgUnit ? async (_dataObject, _memory, requestOptions) => {
                            const definitionProc = this.getDefinitionProc(dataObject.viewName);
                            if (definitionProc) {
                                requestOptions.rowCountOptions.dataObjectOptions.definitionProc = definitionProc;
                                requestOptions.retrieveOptions.dataObjectOptions.definitionProc = definitionProc;
                            }
                        } : undefined,
                    })
                }),
                new DataObjectStepDefinition({
                    stepId: `_${bindingWithEditors.id}_`,
                    dataObjectId: bindingWithEditors.id,
                    title: `Properties Binding With Editors`,
                    onBeforeSync: async (_dataObject, _memory, requestOptions) => {
                        requestOptions.loadPropertyBindingWhereObjects = true;
                        requestOptions.rowCountOptions.dataObjectOptions.whereClause = `ViewName IN (${Array.from(viewNames, (view) => `'${view}'`)})`;
                        requestOptions.retrieveOptions.dataObjectOptions.whereClause = `ViewName IN (${Array.from(viewNames, (view) => `'${view}'`)})`;
                    },
                }),
            ]
        });
    }

    private createPropertyDataObjects(propertyConfigs: Set<PropertyConfig>): { dataObject: any, dataObjectConfig: any }[] {
        const dataObjects = new Array();
        for (let config of propertyConfigs) {
            const dataObject = this.createPropertyDataObjectFromConfig(config);
            if (dataObject) {
                dataObjects.push(dataObject);
            }
        }
        return dataObjects;
    }
    private createPropertybindingWithEditors() {
        const dataObjectId = `dsO365_OFFLINE_PropertiesEditorsWithBinding`;
        const dataObjectConfig = {
            id: dataObjectId,
            viewName: "sviw_System_OfflinePropertiesEditorsWithBinding",
            fields: [
                ...SystemPropertiesFields.map(field => ({ name: field })),
                {
                    name: "PropertyViewName"
                },
                {
                    name: "PropertyUniqueTableName"
                },
                {
                    name: "PropertyBinding"
                },
                {
                    name: "ViewName"
                },
                {
                    name: "PrimKey"
                },
                {
                    name: "PropertyView_PrimKey"
                }
            ],
            maxRecords: -1,
            offline: {
                enableOffline: true,
                jsonDataVersion: 1,
                objectStoreIdOverride: dataObjectId,
                generateOfflineData: false,
                appIdOverride: null,
                databaseIdOverride: null,
                generateOfflineDataProcedureNameOverride: null,
                fieldConfig: {
                    PrimKey: {
                        pwaCompoundId: 1,
                        pwaIsPrimaryKey: true,
                        pwaUseIndex: true
                    },
                    PropertyView_PrimKey: {
                        pwaCompoundId: 1,
                        pwaUseIndex: true,
                        pwaIsPrimaryKey: true
                    }
                }
            },
        }

        app.dataObjectConfigs.set(dataObjectId, dataObjectConfig);
        const dataObject = getOrCreateDataObject(dataObjectConfig, app.id);
        dataObject.enableOffline();
        return [{ dataObject, dataObjectConfig: dataObjectConfig }];
    }

    private async createObjectStores(dataObjects: Array<any>) {
        const indexedDbDatabases = new Map<string, {
            value: Database,
            objectStores: Map<string, {
                value: ObjectStore,
                indexes: Map<string, {
                    value: Index
                }>
            }>
        }>();
        let idbApp = await IndexedDBHandler.getApp(app.id);

        if (!idbApp) {
            throw new Error("Could not find app.");
        }
        indexedDbDatabases.set('DEFAULT', {
            value: (await idbApp!.databases['DEFAULT']) as Database,
            objectStores: new Map()
        });

        const idbDatabaseCache = indexedDbDatabases.get('DEFAULT')!;

        const idbDatabase = idbDatabaseCache.value;

        for (let { dataObject, dataObjectConfig } of dataObjects) {
            const objectStoreId = dataObject.id;
            await this.createObjectStore(dataObject, objectStoreId, idbDatabase, idbDatabaseCache, dataObjectConfig, dataObjectConfig !== undefined);
        }
        await idbApp.initialize();
    }
    private getPropertyConfigs(propertyConfigs: Set<string>): Set<any> {
        return new Set(Array.from(propertyConfigs, (config) => JSON.parse(config)));
    }

    private async createObjectStore(dataObject: any, objectStoreId: string, idbDatabase: Database, idbDatabaseCache: {
        value: Database;
        objectStores: Map<string, {
            value: ObjectStore;
            indexes: Map<string, {
                value: Index;
            }>;
        }>;
    }, dataObjectConfig?: any, initializeDataObject?: boolean) {
        let idbObjectStore = await IndexedDBHandler.getObjectStore(app.id, idbDatabase.id, objectStoreId);

        let fields: Array<string>;

        try {
            fields = dataObject.fields.fields.map((field: any) => field.name);
        } catch (reason) {
            console.error(reason);

            fields = new Array();
        }

        if (idbObjectStore === null) {
            idbObjectStore = await IndexedDBHandler.createObjectStore(app.id, idbDatabase.id, objectStoreId, dataObject.offline.jsonDataVersion, fields, true, dataObjectConfig, initializeDataObject);
        } else if (
            idbObjectStore.jsonDataVersion !== dataObject.offline.jsonDataVersion ||
            idbObjectStore.fields?.length !== fields.length ||
            new Set([...idbObjectStore.fields, ...fields]).size !== (new Set(fields)).size
        ) {
            idbObjectStore.jsonDataVersion = dataObject.offline.jsonDataVersion;
            idbObjectStore.fields = fields;

            await idbObjectStore.save();
        }

        if (idbDatabaseCache.objectStores.has(idbObjectStore.id) === false) {
            idbDatabaseCache.objectStores.set(idbObjectStore.id, {
                value: idbObjectStore,
                indexes: new Map()
            });
        }
        let idbObjectStoreCache = idbDatabaseCache.objectStores.get(idbObjectStore.id)!;
        const indexConfigs = dataObject.offline.indexedDBIndexes;

        for (const indexConfig of indexConfigs) {
            let idbIndex = await IndexedDBHandler.getIndex(app.id, idbDatabase.id, idbObjectStore.id, indexConfig.id);

            if (idbIndex === null) {
                idbIndex = await IndexedDBHandler.createIndex(
                    app.id,
                    idbDatabase.id,
                    idbObjectStore.id,
                    indexConfig.id,
                    indexConfig.keyPath,
                    indexConfig.isPrimaryKey,
                    indexConfig.isUnique,
                    indexConfig.isMultiEntry,
                    indexConfig.isAutoIncrement
                );
            }

            idbObjectStoreCache.indexes.set(idbIndex.id, {
                value: idbIndex
            });
        }
    }

    private parseColumnsFromString(pColumns: string): NonNullable<RecordSourceOptions['fields']> {
        return pColumns.split(',').map(column => {
            const values = column.split(':');
            return ({
                name: values[0],
                size: values[1],
                type: values[2] as DataObjectDefinitionFieldType['type']
            });
        });
    }


    private createPropertyDataObjectForLookup(config: PropertyConfig) {
        let dataObjectId = "dsO365_OFFLINE_PropertiesLookup_" + config.ViewName;
        const dataObjectConfig = {
            id: dataObjectId,
            viewName: config.ViewName,
            fields: this.parseColumnsFromString(config.Columns!),
            offline: {
                enableOffline: true,
                jsonDataVersion: 1,
                objectStoreIdOverride: dataObjectId,
                generateOfflineData: false,
                appIdOverride: null,
                databaseIdOverride: null,
                generateOfflineDataProcedureNameOverride: null
            }
        }

        app.dataObjectConfigs.set(dataObjectId, dataObjectConfig);

        const dataObject = getOrCreateDataObject(dataObjectConfig, app.id);
        dataObject.enableOffline();


        return { dataObject, dataObjectConfig };
    }
    private createPropertyDataObjectFromConfig(config: PropertyConfig) {
        if (config.Type === "Lookup") {
            return this.createPropertyDataObjectForLookup(config);
        } else if (config.Type === "OrgUnit") {
            return this.createPropertyDataObjectForOrgUnit(config);

        } else if (config.Type === "Object") {
            return this.createPropertyDataObjectForObject(config);
        }
        return null;
    }
    private createPropertyDataObjectForObject(config: PropertyConfig) {
        let dataObjectId = "dsO365_OFFLINE_PropertiesLookup_Objects";
        config.ViewName = "aviw_Assets_ObjectsLookup";

        const fields: Array<{ name: string; type?: string; sortOrder?: number; sortDirection?: string }> = [
            { name: "ID" },
            { name: "PrimKey" },
            { name: "Name" },
            { name: "Description" },
            { name: "ObjectType" },
            { name: "ObjectType_ID", type: "number", sortOrder: 1, sortDirection: "asc" },
            { name: "TypeAndName" },
            { name: "SuppliedBy" },
            { name: "SuppliedBy_ID", type: "number" },
            { name: "InstalledBy_ID", type: "number" },
            { name: "InstalledBy" },
            { name: "OrgUnit" },
            { name: "OrgUnit_ID", type: "number" },
            { name: "Component" },
            { name: "Component_ID", type: "number" },
            { name: "Status" },
            { name: "UniqueID" },
            { name: "IsBuilding", type: "boolean" },
            { name: "IsRoom", type: "boolean" },
            { name: "IsSite", type: "boolean" },
            { name: "IsLocation", type: "boolean" },
            { name: "IsSystem", type: "boolean" },
            { name: "IsArea", type: "boolean" },
        ];

        const dataObjectConfig = {
            id: dataObjectId,
            viewName: config.ViewName,
            fields: fields,
            offline: {
                enableOffline: true,
                jsonDataVersion: 1,
                objectStoreIdOverride: dataObjectId,
                generateOfflineData: false,
                appIdOverride: null,
                databaseIdOverride: null,
                generateOfflineDataProcedureNameOverride: null,
                subConfigs: {
                    data: {
                        distinctRows: false,
                        disableAutoLoad: true,
                        selectFirstRowOnLoad: true,
                        maxRecords: 50,
                        fields: fields,
                        dynamicLoading: true,
                        disableLayouts: false,
                        allowInsert: false,
                        allowUpdate: false,
                        allowDelete: false
                    }
                }
            }
        }

        app.dataObjectConfigs.set(dataObjectId, dataObjectConfig);

        const dataObject = getOrCreateDataObject(dataObjectConfig, app.id);
        dataObject.enableOffline();

        return { dataObject, dataObjectConfig };
    }
    private createPropertyDataObjectForOrgUnit(config: PropertyConfig) {
        let dataObjectId = "dsO365_OFFLINE_PropertiesLookup_OrgUnits";
        config.ViewName = "stbv_System_OrgUnits";

        const fields: Array<{ name: string; type?: string; sortOrder?: number; sortDirection?: string }> =
            [
                { name: "PrimKey", type: "string" },
                { name: "ID", type: "number" },
                { name: "IdPath", type: "string" },
                { name: "OrgUnit", type: "string" },
                { name: "Closed", type: "date" },
                { name: "Name", type: "string" },
                { name: "Title", type: "string" },
                { name: "Domain_ID", type: "number" },
                { name: "Level", type: "number" },
                { name: "UnitType", type: "string" },
                { name: "Parent", type: "string" },
                { name: "AccessIdPath", type: "string" }
            ];

        const dataObjectConfig = {
            id: dataObjectId,
            viewName: config.ViewName,
            fields: fields,
            offline: {
                enableOffline: true,
                jsonDataVersion: 1,
                objectStoreIdOverride: dataObjectId,
                generateOfflineData: false,
                appIdOverride: null,
                databaseIdOverride: null,
                generateOfflineDataProcedureNameOverride: null,
                subConfigs: {
                    data: {
                        loadRecents: true,
                        distinctRows: true,
                        maxRecords: 25,
                        fields: fields
                    },
                    tree: {
                        selectFirstRowOnLoad: false,
                        loadRecents: false,
                        maxRecords: 25,
                        fields: fields
                    },
                }
            },
        }

        app.dataObjectConfigs.set(dataObjectId, dataObjectConfig);

        const dataObject = getOrCreateDataObject(dataObjectConfig, app.id);
        dataObject.enableOffline();

        return { dataObject, dataObjectConfig };
    }
    private getDefinitionProc(viewName: string): string | null {
        switch (viewName) {
            case "aviw_Assets_ObjectsLookup": {
                return "astp_Assets_ObjectsLookupDefintion";
            }
            case "stbv_System_OrgUnits": {
                return null;
            }
            default: {
                return null;
            }
        }
    }
}
