import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type Index from 'o365.pwa.modules.client.dexie.objectStores.Index.ts';
import type * as ObjectStoreModule from 'o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts';
interface IDataObjectConfig {
    id: string;
    viewName: string;
    fields: { name: string }[]
    offline: {
        enableOffline: boolean,
        jsonDataVersion: number,
        objectStoreIdOverride: string,
        generateOfflineData: boolean,
        appIdOverride: string | null,
        databaseIdOverride: string | null,
        generateOfflineDataProcedureNameOverride: string | null
    }
}
export class ObjectStore implements ObjectStoreModule.ObjectStore {
    static objectStoreDexieSchema: string = "&[appId+databaseId+id], [appId+databaseId], appId, databaseId, id";

    public id!: string;
    public appId!: string;
    public databaseId!: string;
    public jsonDataVersion!: number | null;
    public fields!: Array<string> | null;
    public isPropertyTable!: boolean;
    public dataObjectConfig?: IDataObjectConfig;
    public initializeDataObject?: boolean;

    public get indexes() {
        const objectStore = this;

        return new Proxy<ObjectStoreModule.Indexes>(<ObjectStoreModule.Indexes>{
            getAll: async () => {
                return await IndexedDbHandler.getIndexes(objectStore.appId, objectStore.databaseId, objectStore.id);
            }
        }, {
            get(target, prop, receiver) {
                if (prop in target) {
                    return Reflect.get(target, prop, receiver);
                }

                return new Promise<Index | null>(async (resolve, reject) => {
                    try {
                        const index = await IndexedDbHandler.getIndex(objectStore.appId, objectStore.databaseId, objectStore.id, prop.toString());

                        resolve(index);
                    } catch (reason) {
                        reject(reason);
                    }
                });
            }
        });
    }

    public get schema(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const indexes = await this.indexes.getAll();

                let objectStoreSchema = '';
                let seperator = '';
                let hasPrimaryKey = false;

                for (const index of indexes) {
                    if (index.isPrimaryKey) {
                        objectStoreSchema = index.schema + seperator + objectStoreSchema;
                        hasPrimaryKey = true;
                    } else {
                        objectStoreSchema += seperator + index.schema;
                    }

                    seperator = ', ';
                }

                if (indexes.length === 0) {
                    objectStoreSchema = '++';
                } else if (hasPrimaryKey === false) {
                    objectStoreSchema = '++, ' + objectStoreSchema;
                }

                resolve(objectStoreSchema);
            } catch (reason) {
                reject(reason);
            }
        });
    }

    constructor(id: string, databaseId: string, appId: string, jsonDataVersion: number | null, fields: Array<string> | null, isPropertyTable: boolean, dataObjectConfig: IDataObjectConfig | undefined = undefined, initializeDataObject: boolean = false) {
        this.id = id;
        this.databaseId = databaseId;
        this.appId = appId;
        this.jsonDataVersion = jsonDataVersion;
        this.fields = fields;
        this.isPropertyTable = isPropertyTable;
        this.dataObjectConfig = dataObjectConfig;
        this.initializeDataObject = initializeDataObject;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateObjectStore(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteObjectStore(this);
    }

    public async forceReload(): Promise<ObjectStore | null> {
        return await IndexedDbHandler.getObjectStoreFromIndexedDB(this.appId, this.databaseId, this.id);
    }
}

export default ObjectStore;
