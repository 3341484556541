<script setup>
    import { ref, reactive, onMounted, onUnmounted, inject } from 'vue';
    import { getOrCreateDataObject, deleteDataObject } from 'o365-dataobject';

    const random_number = Math.floor(Math.random() * (1000000 - 10 + 1)) + 10; // Creates a random number which will be used on the datasource names (this is purely because the ds's cant be named the same) 
    let countedRiskIDs = ref(null);
    let risks = reactive([]);
    let risks_loaded = ref(false);
    let maxHeight = ref("67px;"); 
    let maxWidth = ref("67px;");
    let fontSize = ref("0.7em;")
    let style_added = ref(false);
    
    const popoverTrigger = ref(null);
    let popover = ref(null);
    let popoverContent = ref("");
    const { updateToggleEditModal } = inject('toggleEditModal', { updateToggleEditModal: (value) => {}})
    
    const emits = defineEmits(['toggleSpinner', 'dataCell']);

    const props = defineProps({
        "Source": String,
        "UncertaintyType": String,
        "MatrixSize": String, 
        "RiskIDs": {
            type: Array, 
            default: () => []
        },
        "MatrixPValue": Number,
        "MatrixCValue": Number,
        "MaxCellValue": Number,
        "ConsequenceArea_ID": String,
        "DetailAppAssessments": { // Contains current row of Consequence-assessment and Probability-assessment from detail app
            type: Array,
            default: () => []
        },
        "EditEnabled": {
            type: Boolean,
            default: false
        },
        "ButtonColor":{
            type: String,
            default: "black"
        },
        "IsStatusElement":{
            type: Boolean,
            defailt: false
        },
        "LastCellLoaded":{
            type: Boolean,
            defailt: false
        }
    });
    const local_dsCurrentAssesments = getOrCreateDataObject({ // Datasource used for "Overall"
        id: `dsCurrentAssesments_${props.UncertaintyType}_${random_number}`,
        viewName: 'aviw_RiskManager_CurrentAssessments', maxRecords: -1,
        loadRecents: false,
        disableLayouts: true,
        fields: [
            { name: "Uncertainty_ID", type: "int"},
            { name: "Type", type: "string"},
            { name: "Name", type: "string"},
            { name: "CurrentPValue", type: "int"},
            { name: "CurrentCValue", type: "int"},
            { name: "Criticality", type: "int"},
            { name: "PreviousCriticality", type: "int"}
        ]
    });

    const local_dsCurrentAssesmentsAll = getOrCreateDataObject({ // Datasource used for consequenceareas
        id: `dsCurrentAssesmentsAll_${props.UncertaintyType}_${random_number}`,
        viewName: 'aviw_RiskManager_CurrentAssessmentsAllConsequenceAreas', maxRecords: -1,
        loadRecents: false,
        disableLayouts: true,
        fields: [
            { name: "Uncertainty_ID", type: "int"},
            { name: "Type", type: "string"},
            { name: "Name", type: "string"},
            { name: "CurrentPValue", type: "int"},
            { name: "CurrentCValue", type: "int"},
            { name: "Criticality", type: "int"},
            { name: "PreviousCriticality", type: "int"},
            { name: "ConsequenceArea_ID", type: "int"}
        ]
    });
    
    onMounted(() => {
        createCellData(props.Source, props.UncertaintyType, props.MatrixSize, props.ConsequenceArea_ID, props.MatrixCValue, props.MatrixPValue, props.RiskIDs);
    });

    onUnmounted(() => {
        deleteDataObject(local_dsCurrentAssesments.id, local_dsCurrentAssesments.appId);
        deleteDataObject(local_dsCurrentAssesmentsAll.id, local_dsCurrentAssesmentsAll.appId);
    });

    const createCellData = async (pSource, pUncertaintyType, pMatrixSize, pConsequenceArea_ID, pCValue, pPValue, pRiskIDs) => {
        if(pSource !== 'Risk') { return; }  // Celldata should not render unless its used in Risk-module 
        if(pMatrixSize === 'table-matrix-xs' && props.IsStatusElement === false ) { return; } // Celldata is managed direcly in the HTML for XS-matrix' (used in risk detail app)
        if (pRiskIDs.length === 0) { return; } // Shouldn't create celldata if there's no risk-ids associated with the cell 
        
        if(props.MatrixPValue === 1 && props.MatrixCValue === 1) { // Start loading of spinner at first cell
            emits('toggleSpinner', true);
        }

        if(pConsequenceArea_ID !== "null") { // When a consequence-area is selected in the toolbar
            const whereClause = "Type = '" + pUncertaintyType + "' AND CurrentCValue = " + pCValue + " AND CurrentPValue = " + pPValue + " AND Uncertainty_ID IN (" + pRiskIDs + ") AND ConsequenceArea_ID = " + pConsequenceArea_ID;
            
            local_dsCurrentAssesmentsAll.recordSource.whereClause = whereClause; 
            await local_dsCurrentAssesmentsAll.load().then((data) => {
                data.forEach(risk => {
                    risks.push({
                        Uncertainty_ID:         risk.item.Uncertainty_ID,
                        Name:                   risk.item.Name,
                        Criticality:            risk.item.Criticality,
                        PreviousCriticality:    risk.item.PreviousCriticality
                    });

                    popoverContent.value += '<a class="d-inline-flex mb-1 mt-1" href="/risk?ID=' + risk.item.Uncertainty_ID + '" target="_risk">' + risk.item.Uncertainty_ID + ' - ' + risk.item.Name +  '</a><br>'
                });

                countedRiskIDs.value = risks.length;
                
                risks_loaded.value = true;
            });
        } else { // When "Overall" is selected in the consequence area lookup
            const whereClause = "Type = '" + pUncertaintyType + "' AND CurrentCValue = " + pCValue + " AND CurrentPValue = " + pPValue + " AND Uncertainty_ID IN (" + pRiskIDs + ")"
            
            local_dsCurrentAssesments.recordSource.whereClause = whereClause; 
            await local_dsCurrentAssesments.load().then((data) => {
                data.forEach(risk => {
                    risks.push({
                        Uncertainty_ID:         risk.item.Uncertainty_ID,
                        Name:                   risk.item.Name,
                        Criticality:            risk.item.Criticality,
                        PreviousCriticality:    risk.item.PreviousCriticality
                    });

                    popoverContent.value += '<a class="d-inline-flex mb-1 mt-1" href="/risk?ID=' + risk.item.Uncertainty_ID + '" target="_risk">' + risk.item.Uncertainty_ID + ' - ' + risk.item.Name +  '</a><br>'
                });
                
                countedRiskIDs.value = risks.length;

                risks_loaded.value = true;
            });
        }
        
        if(pMatrixSize === 'table-matrix-md') { // Medium matrix: risk id's displayed w/ fitting style and popover
            maxHeight.value = "67px;"
            maxWidth.value = "67px;"
            fontSize.value = "11px;"
            
            style_added.value = true;
        } else if(pMatrixSize === 'table-matrix-lg') { // Large matrix: risk id's displayed w/ fitting style and popover
            maxHeight.value = "97px;"
            maxWidth.value = "97px;"
            fontSize.value = "13px;"
            
            style_added.value = true;
        }

        // Stop spinner when last cell is loaded/populated
        if(props.LastCellLoaded){
            emits('toggleSpinner', false);
        }
    }

    const togglePopover = () => {
        if(popover.value === null) { // Initializing popover when its first clicked, popovers initial value is null
            popover = new bootstrap.Popover(popoverTrigger.value, {
                content: popoverContent.value,
                html: true
            });

            popover.toggle();
        }
    }
    const cellPosition = ref(null);
    let emittedValues = {
        Clicked : true,
        MatrixCValue: props.MatrixCValue,
        MatrixPValue: props.MatrixPValue,
        CellLeftPosition: 0,
        CellTopPosition: 0,
        Cell : {}
    };


    
    const checkEditEnabled = () => {
        if(!props.EditEnabled) {
            updateToggleEditModal(true); // Toggling edit modal in risk detail app
            // alert($t("Edit assessment to change current assessment or register new assessment to keep history"), 'danger', { autohide: true,  delay: 3000})
        }else{
            emittedValues.CellLeftPosition = cellPosition.value.offsetParent.offsetLeft;
            emittedValues.CellTopPosition = cellPosition.value.offsetParent.offsetTop;
            emittedValues.Cell =  cellPosition.value;
            emits('dataCell' , emittedValues);
        }
    }

    // const setCurrentAssessment = () => {
        // props.DetailAppAssessments[0].Value = props.MatrixCValue; // Setting current consequence assessment value to the matrix's C cell value
        // props.DetailAppAssessments[1].current.Value = props.MatrixPValue; // Setting current probability assessment value to the matrix's P cell value
        // props.DetailAppAssessments[2].save(); // -> dsConsAssessments.save()
        // props.DetailAppAssessments[1].save(); // -> dsProbAssessments.save()
    // }
</script>

<template>
    <div class="main-div" :class="{ 'btn btn-borderless' : (props.MatrixSize === 'table-matrix-xs' && props.IsStatusElement === false) }"> <!-- Adding this class so the div is clickable without any content inside -->
        <!-- Orig, Prev and Curr - Matrix extra small -->
        <div
            ref="cellPosition"
            v-if="props.MatrixSize === 'table-matrix-xs' && props.IsStatusElement === false"
            class="btn btn-borderless btn-color fw-bold boxCell"
            style="max-width:25px; max-width:25px;"
            @click="checkEditEnabled"
            :disabled="!props.EditEnabled"
        >   
            <!-- Original -->
            <span
                v-if="(props.MatrixCValue === props.DetailAppAssessments[0]?.OrigCValue && props.MatrixPValue === props.DetailAppAssessments[0]?.OrigPValue)"
                :title="$t('Original (') + props.DetailAppAssessments[2]?.current.OrigCreatedBy + ', ' + $formatDate(props.DetailAppAssessments[2]?.current.OrigDate, 'Short Date') + ')'"
                style="cursor:help; font-size:0.75rem;"
            >{{ $t("O") }}</span>

            <!-- Previous -->
            <span
                v-if="(props.MatrixCValue === props.DetailAppAssessments[0]?.PrevCValue && props.MatrixPValue === props.DetailAppAssessments[0]?.PrevPValue)"
                :title="$t('Previous (') + props.DetailAppAssessments[2]?.current.PrevCreatedBy + ', ' + $formatDate(props.DetailAppAssessments[2]?.current.PrevDate, 'Short Date') + ')'"
                style="cursor:help; font-size:0.75rem;"
            >{{ $t("P") }}</span>

            <!-- Current -->
            <span
                class="text-decoration-underline"
                v-if="(props.MatrixCValue === props.DetailAppAssessments[0]?.Value && props.MatrixPValue === props.DetailAppAssessments[1]?.current.Value)"
                :title="$t('Current (') + props.DetailAppAssessments[2]?.current.CreatedBy + ', ' + $formatDate(props.DetailAppAssessments[1]?.current.Date, 'Short Date') + ')'"
                style="cursor:help; font-size:0.75rem;"
            >{{ $t("C") }}</span>
        </div>

        <!-- Counted risks - Matrix small -->
        <a
            v-if="(props.MatrixSize === 'table-matrix-sm' || (props.IsStatusElement === true && props.MatrixSize === 'table-matrix-xs')) && risks_loaded && countedRiskIDs > 0"
            class="counted_risks d-flex justify-content-center align-items-center"
            href="#"
            ref="popoverTrigger"
            @click="togglePopover"
            data-bs-toggle="popover"
            :data-bs-content="popoverContent"
        >
            {{ countedRiskIDs }}
        </a>

        <!-- Risk id's - Matrix medium and large -->
        <div  
            class="risk_ids" :style="'max-height:' + maxHeight"
            v-if="(props.MatrixSize === 'table-matrix-md' || props.MatrixSize === 'table-matrix-lg') && risks_loaded && style_added && risks.length > 0" 
        >
            <a class="d-flex align-items-center p-1" :style="'font-size:' + fontSize" v-for="(row, rowIndex) in risks" :key="rowIndex" :href="'/risk?ID=' + row.Uncertainty_ID">
                {{ row.Uncertainty_ID }}
                <i class="bi bi-arrow-up" v-if="(row.Criticality && row.PreviousCriticality) && row.Criticality > row.PreviousCriticality"></i>
                <i class="bi bi-arrow-down" v-if="(row.Criticality && row.PreviousCriticality) && row.Criticality < row.PreviousCriticality"></i>
                <i class="bi bi-dash" v-if="(row.Criticality && row.PreviousCriticality) && row.Criticality === row.PreviousCriticality"></i>
            </a>
        </div>
    </div>
</template>

<style scoped>
    .main-div {
        opacity: 0;
        animation: fadeInAnimation 0.5s ease-in-out forwards;
    }

    @keyframes fadeInAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .risk_ids {
        overflow-y: auto;
    }

    .risk_ids a {
        font-weight: inherit;
        text-decoration: none;
        padding: 3px;
        display: block;
        color: inherit;
    }

    .risk_ids a:hover {
        text-decoration-line: underline;
    }

    .counted_risks {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        text-decoration: none;
        font-weight: inherit;
        color: inherit;
    }
    
    /* Borderless btn */
    .btn-borderless {
        border: none;
        width: inherit;
        height: inherit;
        background: none;
        padding: 0;
        margin: 0;
        outline: none;
    }

    /* Make sure the text inside the button doesn't react to anything */
    .btn-borderless * {
        pointer-events: stroke;
            /* :style="'color:'+props.ButtonColor" */
        
    }
    .btn-color  {
        color: v-bind(ButtonColor);
    }

    /* Remove any potential hover or active effects on the button itself */
    .btn-borderless:hover, .btn-borderless:active, .btn-borderless:focus {
        background: none;
        text-decoration: none;
    }
</style>