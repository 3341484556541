<script setup>
import { onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';

const props = defineProps({
    sjaID: Number,
    readOnly: Boolean
});

const dsRelatedDocuments = getOrCreateDataObject({
    id: `dsRelatedDocuments_${props.sjaID}`,
    uniqueTable: 'atbl_SJA_SJADocuments',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJADocuments',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        { name: 'Document_ID', },
        { name: 'DocumentTitle', },
        { name: 'OrgUnit_ID', },
        { name: 'OrgUnit' }
    ],
    whereClause: "SJA_ID = " + props.sjaID
});

const dsRelatedDocumentsLkp = getOrCreateDataObject({
    id: `dsRelatedDocumentsLkp_${props.sjaID}`,
    maxRecords: 25,
    viewName: 'aviw_Arena_DocumentsLookup',
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'OrgUnit', },
        { name: 'Rev', },
        { name: 'Title', },
        { name: 'OrgUnitIdPath' }
    ],
});

function addRelatedDocument(pRow) {
    dsRelatedDocuments.createNew({
        SJA_ID: props.sjaID,
        Document_ID: pRow.ID
    });
}

onMounted(() => {
    dsRelatedDocuments.load();
})

</script>

<template>
    <ODataLookup v-if="!props.readOnly" :data-object="dsRelatedDocumentsLkp" :bind="sel => { addRelatedDocument(sel); }" :noClear="true" contextField="OrgUnitIdPath">
        <OColumn field="ID" :headerName="$t('ID')" width="80"></OColumn>
        <OColumn field="OrgUnit" :headerName="$t('Org Unit')" width="200"></OColumn>
        <OColumn field="Rev" :headerName="$t('Revision')" width="100"></OColumn>
        <OColumn field="Title" :headerName="$t('Title')" width="300"></OColumn>
        <template #target="scope" @click="scope.open">
            <button :ref="scope.target" class="p-0 btn btn-link">
                <div class="d-flex" :title="$t('Link a related document to the SJA')">
                    <h5>{{ $t('Related documents') }}</h5>
                    <i class="mx-1 bi bi-plus-circle-fill"></i>
                </div>
            </button>
        </template>
    </ODataLookup>

    <div v-if="props.readOnly" class="d-flex">
        <h5>{{ $t('Related documents') }}</h5>
    </div>

    <hr class="m-0" />
    <div class="d-flex flex-wrap mt-2">
        <div v-for="(item, index) in dsRelatedDocuments.data" :key="index" class="d-flex">
            <div class="mt-2 me-4" v-if="!item.isNewRecord">
                <span class="d-inline-flex align-items-center">
                    <a :href="`/documentregister/document?ID=${item.Document_ID}`" target="_blank">
                        <span>{{ item.Document_ID }} - {{ item.DocumentTitle }}</span>
                    </a>
                    <OActionDelete confirm v-if="!props.readOnly" :dataObject="dsRelatedDocuments" :row="item"
                        class="btn btn-sm btn-link p-0" :title="$t('Delete document')" style="-bs-btn-line-height: 1;">
                        <i class="bi bi-x-circle ms-2"></i>
                    </OActionDelete>
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped></style>